import { Box, Button, Container, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import Navbar from "../../components/NavBar";
import SubscriptionCards from "../../components/Cards/subscriptionCards";
import Credits from "../../components/Cards/credits";
import BasicTable from "../../components/Table";
import HomeAccordion from "../../components/Accordion";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAuth } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import slices from "../../store/user/userSlice";
import checkoutSlice from "../../store/CheckoutData/checkoutDataSlice";

const Subscriptions = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.reducer?.user);
  const { login } = useAuth();
  const [cardsData, setCardsData] = useState(userState?.subscriptionPlanDetail);
  const [currentPlan, setCurrentPlan] = useState(false);
  const [reserveCredits, setReserveCredits] = useState(0);
  const [monthlyCredits, setMonthlyCredits] = useState(0);
  const [currentPlanName, setCurrentPlanName] = useState("");
  const effectCount = useRef(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reservedCredits = (data) => {
    let reserveCredits = 0;
    let monthlyCredits = 0;
    data?.map((row) => {
      if (row?.expirationDate == null) {
        reserveCredits += row?.credits || 0;
      } else {
        monthlyCredits += row?.credits || 0;
      }
    });

    setReserveCredits(reserveCredits);
    console.log("reserveCredits", setReserveCredits(reserveCredits));
    setMonthlyCredits(monthlyCredits);
  };

  const authenticatedUser = async () => {
    const token = userState?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "authenticatedUser", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        const updatedUserData = {
          ...userState,
          subscriptionData: response.data.data.subscriptionData,
          subscriptionPlan: response.data.data.subscriptionPlan,
          creditData: response.data.data.creditData,
        };
        dispatch(slices.setUser(updatedUserData));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (effectCount.current < 5) {
      // Set a timeout to call fetchData after certain intervals
      const timeouts = [setTimeout(authenticatedUser, 1000), setTimeout(authenticatedUser, 5000), setTimeout(authenticatedUser, 10000), setTimeout(authenticatedUser, 15000)];

      // Cleanup function to clear the timeout if component unmounts before 15 seconds
      return () => {
        timeouts.forEach(clearTimeout);
      };
    }

    if (effectCount.current === 0) {
      effectCount.current++;
    }
  }, []);

  useEffect(() => {
    setCurrentPlan(userState?.subscriptionData[0]?.plan);
    reservedCredits(userState?.creditData);
    setCurrentPlanName(userState?.subscriptionPlan?.name);
  }, [userState?.subscriptionData[0], userState?.creditData]);

  useEffect(() => {
    dispatch(checkoutSlice.clearCheckoutData());
  }, []);

  const creditsData = [
    { id: 1, credits: 500, price: 49.99 },
    { id: 2, credits: 1000, price: 99.99 },
    { id: 3, credits: 2500, price: 249.99 },
    { id: 4, credits: 5000, price: 499.99 },
    { id: 5, credits: 11000, price: 999.99 },
  ];

  // state of faqs
  // const [expanded, setExpanded] = useState("panel1");
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqs = [
    {
      title: "How does the platform ensure accuracy and relevance of the generated pleadings?",
      content: `AI systems are not perfect. Attorneys shall and must carefully review all memorandums and citations for substantive accuracy. We cannot control AI generated output. The goal of our system is to speed up the process of typing pleadings. We strive to obtain accurate and relevant results through a process called "prompt engineering" in AI systems. However, we cannot control the quality of the information input into the system, nor the computer generated output either.`,
    },
    {
      title: "How is sensitive client information protected?",
      content: `You may only utilize our platform  with information in cases that may be subject to public exposure when filing a pleading with the clerk of court. The information you enter into the system will be transferred through various computer systems outside of our direct control. Do not utilize our platform if information is sealed by court order.`,
    },
    {
      title: "How are facts and law entered into our platform?",
      content: `Currently, the proper method of entering information into our system is copy and paste. However, we are actively working on technologies to assist in automatically finding relevant facts and law from depositions, records, expert reports, and case law, respectively.`,
    },
    {
      title: "Does Magic Pleadings find case law for you?",
      content: `Our platform is not directly tied to any case law provider, such as Westlaw, FastCase, or Casetext. As the attorney, you must find the two best on-point cases that match your case. However, once you've found your two cases, don't type the information into our system. Just copy and paste the facts, law, and court's analysis into our system. The magic of AI will evaluate the court's ruling and apply the same logic and reason to your case specific facts. Our system will produce extraordinary results in only a few minutes.`,
    },
    {
      title: "Is there any training required to operate the platform?",
      content: `You need no training to operate our system. Our system will guide you step by step through the entire process of creating a new pleading. All you need to do is answer a few questions and copy and paste your information into our system.`,
    },
    {
      title: "How does the system provide me with a copy of the pleadings?",
      content: `Once the AI drafts your pleading, you will receive a Word file via email.`,
    },
    {
      title: "Is there a trial period or demo available to test the platform before committing to a subscription?",
      content: `Yes, our system is currently FREE to use. Magic Pleadings recently launched our initial beta platform MP1.0. We are in the process of testing our system and further refining our processes, so our system is free to use.  `,
    },
    {
      title: "How much does Magic Pleadings cost?",
      content: `Magic Pleadings is currently FREE to use, so take advantage of our system! During our beta testing, we will monitor our costs to utilize ChatGPT and determine an appropriate monthly subscription price. `,
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#222131" }}>
      <Navbar />
      <Container
        sx={{
          maxWidth: { xs: "md", xl: 1400 },
          height: "auto",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url('magic-board.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: { xs: "100% 110%", sm: "75%", xl: "48%" },
            height: { xs: "400px", sm: "450px" },
            backgroundPosition: { xs: "center center", sm: "right center" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "230px",
              width: "100%",
              border: "2px solid white",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#1F1E30",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {/* <Box
                    sx={{
                      width: "80px",
                      height: "auto",
                      borderRadius: "20px",
                      marginRight: "20px",
                    }}
                    component="img"
                    src={`monthly-credits.png?fit=crop&auto=format`}
                  ></Box> */}
                  <Typography variant="h6" sx={{ color: "white", fontFamily: "poppins" }}>
                    Monthly Credits
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontFamily: "poppins",
                        color: "#686DFC",
                        fontSize: "30px",
                      }}
                    >
                      {monthlyCredits}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "#686DFC",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "3px 12px",
                        fontSize: "15px",
                        // marginLeft: "20px",
                      }}
                    >
                      {currentPlanName == "Individual Attorney" ? "Free" : currentPlanName}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Box
                    sx={{
                      width: "80px",
                      height: "auto",
                      borderRadius: "20px",
                      marginRight: "20px",
                    }}
                    component="img"
                    src={`reserve-credits.png?fit=crop&auto=format`}
                  ></Box> */}
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      fontFamily: "poppins",
                    }}
                  >
                    Reserve Credits
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontFamily: "poppins",
                        color: "#686DFC",
                        fontSize: "30px",
                      }}
                    >
                      {reserveCredits}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box component="div">
          <Grid container spacing={{ xs: 0, sm: 6 }}>
            <Grid item xs={12} sx={{ marginTop: { xs: "-60px", sm: "-70px" } }}>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontFamily: "Recoletabold",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                Monthly Credit Plans - Save Money
              </Typography>
            </Grid>

            {cardsData?.map((row) => {
              return (
                <Grid key={row._id} item xs={12} sm={6} sx={{ marginTop: { xs: "35px", sm: "0px" } }}>
                  <SubscriptionCards
                    data={{
                      id: row?._id,
                      title: row?.name,
                      price: row?.price,
                      billed: "monthly",
                      noUsers: row?.noUsers,
                      cases: row?.cases,
                      storage: row?.storage,
                      credits: row?.monthlyCredits,
                      creditsTenure: "monthly",
                      currentPlan: row?._id == currentPlan ? true : false,
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ marginY: "20px" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontFamily: "Recoletabold",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                Reserve Credits - One Time Purchase
              </Typography>
            </Grid>
            {creditsData?.map((row) => {
              return (
                <Grid key={row?.id} item xs={12} sm={4}>
                  <Credits data={{ credits: row?.credits, price: row?.price }} />
                </Grid>
              );
            })}

            {/* <Grid item xs={12} sm={4}>
              <Credits data={{ credits: "1000", price: "99.99" }} />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Credits data={{ credits: "2500", price: "249.99" }} />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Credits data={{ credits: "5000", price: "499.99" }} />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Credits data={{ credits: "11000", price: "999.99" }} />
            </Grid> */}
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ marginY: "20px" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontFamily: "Recoletabold",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                Additional Users
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <BasicTable />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ paddingY: "10px" }}>
          <Grid item xs={12} sm={12}>
            <Typography
              sx={{
                display: "block",
                padding: { xs: "10%", sm: "5%" },
                fontSize: "60px",
                color: "#FFFFFF",
                fontFamily: "Recoletabold",
                textAlign: "center",
              }}
            >
              FAQs
            </Typography>
            {faqs.map((faq, index) => (
              <HomeAccordion key={index} text={faq} expanded={expanded === `panel${index + 1}`} handleExpansion={handleExpansion(`panel${index + 1}`)} />
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Subscriptions;
