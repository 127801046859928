import { Box } from "@mui/material";
import Navbar from "../../components/NavBar";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

const Iframe = ({ title, iframeSrc }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Magic Pleadings | {title}</title>
      </Helmet>
      <div style={{ backgroundColor: "#242233" }}>
        <Navbar />
      </div>
      <Box
        sx={{
          position: "relative",
          height: { xs: "100%", sm: "100%" }, // Set the height to 100% of the viewport height
          backgroundColor: "#242233",
          padding: "2px",
          margin: 0, // Reset margin
          overflow: "hidden",
        }}
      >
        <div
          style={{
            marginTop: "5%",
            overflow: "scroll",
            height: "1500px",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <iframe
            src={`${iframeSrc}`}
            width="100%"
            // height="1450px"
            title="Your Embedded Content"
            style={{
              minHeight: "1000px",
              maxHeight: "1600px",
              border: "none",
            }}
          />
        </div>
      </Box>
    </Fragment>
  );
};

export default Iframe;
