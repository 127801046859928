import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navbar from "../components/NavBar";
const PrivacyAndPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box component="div" sx={{ backgroundColor: "#222131" }}>
      <Navbar />

      <Box
        sx={{
          minHeight: "100vh",
          maxHeight: "100%",
          backgroundColor: "#222131",
        }}
      >
        <Container
          component="main"
          sx={{ paddingY: 6, maxWidth: { xs: "md", xl: 1410 } }}
        >
          <Box component="div">
            <Typography
              component="h1"
              variant="h3"
              sx={{
                fontFamily: "Recoletabold",
                textAlign: "center",
                color: "white",
              }}
            >
              Privacy <span style={{ color: "#686DFC" }}>Policy</span>
            </Typography>
            <Typography
              component="p"
              variant="p"
              sx={{
                marginY: "20px",
                paddingX: { xs: "20px", sm: "100px" },
                fontFamily: "poppins",
                textAlign: "center",
                color: "white",
              }}
            >
              Thanks for using Magic Pleadings! Our Privacy Policy details how
              we handle your info. By using Magic Pleadings, you agree to these
              practices.
            </Typography>
          </Box>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
              border: "1px solid #686DFC",
              padding: "30px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#222131",
              color: "white",
            }}
          >
            <Box
              component="div"
              noValidate
              sx={{
                mt: 3,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "justify",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings Privacy & Data Policy describes how Magic
                    Pleadings collects, uses, and shares your personal data.
                    Updated: March 17, 2024 In line with our commitment to
                    transparency, Magic Pleadings provides embedded data and
                    privacy information within our services, specifically for
                    features that require the use of your personal information.
                    This detailed, feature-specific information is highlighted
                    through our Privacy & Data Policy. Before you engage with
                    any of these features, you'll have the chance to review the
                    relevant privacy information. Moreover, you can access this
                    information at any time — whether through the settings of
                    the specific features or online at:
                    magicpleadings.ai/privacyanddata. We encourage you to
                    explore our privacy practices, outlined under the headings
                    below, and do not hesitate to reach out to us with any
                    inquiries.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Collection of Personal Data
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings is committed to protecting your privacy and
                    handling your personal data transparently. This section
                    details the types of personal data we collect and how we
                    acquire it. When you use Magic Pleadings, we collect basic
                    personal information necessary to provide you with our
                    services. This includes but is not limited to: Contact
                    Information: We collect your contact details to communicate
                    with you regarding the documents you draft using our
                    services and to send completed pleadings via email. Billing
                    Information: To process payments for our services, we
                    collect billing information corresponding to the payment
                    method you choose. Device-Specific Information: Magic
                    Pleadings does not collect device-specific information such
                    as hardware model or operating system version. Sensitive
                    Personal Data: Magic Pleadings does not collect sensitive
                    personal data, including but not limited to biometrics,
                    health information, or financial information beyond what is
                    required for billing purposes. Our priority is to only use
                    your personal data to enhance your experience with Magic
                    Pleadings and fulfill the services you request from us.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Use of Personal Data
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    At Magic Pleadings, we use the personal data collected from
                    our users with a clear focus on delivering and improving the
                    services we provide. Below, we outline the purposes for
                    which your personal data is used: Communication and Service
                    Delivery: We utilize your contact information to send you
                    completed documents via email, ensure you are informed about
                    the progress of your requests, and address any queries you
                    might have about our services. Billing: The billing
                    information collected is strictly used for processing
                    payments for our services, ensuring a smooth transactional
                    experience. Support and Efficiency: By understanding your
                    needs and preferences, we leverage the personal data
                    collected to provide timely support assistance. This data
                    helps streamline your experience, making the generation of
                    pleadings and documents faster and more efficient.
                    Improvement of AI and Automation Algorithms: It is important
                    to note that we do not use the personal data collected for
                    the improvement of our AI and automation algorithms.
                    Personalization: The knowledge we gain from the personal
                    data collected assists in tailoring our services to better
                    match your specific needs, enhancing your overall experience
                    with Magic Pleadings. Our commitment is to use your personal
                    data solely for the purposes of enhancing the service
                    offered to you, maintaining the integrity and
                    confidentiality of your information at all times.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Sharing of Personal Data
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Our users' privacy is paramount at Magic Pleadings. This
                    section explains our practices around the sharing of
                    personal data collected through our services. Personal Data
                    Sharing: As a fundamental policy, Magic Pleadings does not
                    share any personal data collected directly from our users
                    with third parties, except as outlined below. AI Partners:
                    In order to provide the full extent of our services, data,
                    including any sensitive data you choose to upload to our
                    system, necessary for generating pleadings and documents is
                    sent to our AI partners. This data is processed exclusively
                    for the purpose of delivering the services you request.
                    These AI partners are contractually obligated to ensure the
                    security and confidentiality of your data, adhering to our
                    strict privacy standards. Lawful Requests by Government
                    Bodies: There may be circumstances under which we are
                    required to share your data with government bodies. This
                    includes situations where we are responding to a lawful
                    subpoena. In such cases, we strictly limit the data shared
                    to what is legally requested and take proactive measures to
                    notify you, where permitted, about the request. Commitment
                    to Transparency: We are committed to maintaining
                    transparency about our data sharing practices. Magic
                    Pleadings will only share personal data when necessary for
                    the provision of our services or as required by law, always
                    prioritizing the privacy and security of your information.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    User Privacy Rights
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings is dedicated to ensuring that our users
                    maintain control over their personal data. This section
                    highlights your rights regarding the personal data we hold
                    and how you can exercise these rights. Access to Personal
                    Data: You have the right to request information about the
                    personal or business data that Magic Pleadings has stored in
                    our system. This includes obtaining details on what data is
                    held, the purpose of processing, and to whom the data may
                    have been shared. Correction of Personal Data: If any
                    personal data in our possession is inaccurate or incomplete,
                    you have the right to request a correction. We are committed
                    to ensuring that your information is accurate and up to
                    date. Deletion of Personal Data: You also have the right to
                    request the deletion of your personal data from our system.
                    We will comply with these requests, subject to any legal
                    requirements that may require us to retain certain
                    information. How to Exercise Your Rights: To exercise any of
                    the above rights, please submit a formal written request to
                    us. Upon receipt of your request, we will respond in a
                    timely manner, providing details on the actions taken to
                    address your request. By empowering you with these rights,
                    we aim to foster a transparent and trustworthy environment
                    where your privacy is respected and protected.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Data Security
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings is committed to protecting the security of
                    your personal data. We employ state-of-the-art web services
                    designed to secure your data against unauthorized access,
                    alteration, disclosure, or destruction. Security Measures:
                    We use advanced security technologies and procedures to
                    protect your personal data. Our security measures are
                    designed to provide a level of security appropriate to the
                    risk of processing your personal information. Monitoring: To
                    further ensure the integrity of our services and your data,
                    we continuously monitor for indications of a breach. In the
                    unlikely event of a security breach, we are prepared to take
                    immediate action to mitigate any potential harm.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    International Data Transfers
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Data Location: All personal data collected by Magic
                    Pleadings is processed using web services and AI partners
                    located within the United States. We do not transfer
                    personal data across international borders outside of these
                    jurisdictions.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Cookies and Similar Technologies
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings utilizes basic cookies and standard tokens
                    to facilitate user registration and logins. These
                    technologies are essential for the operation of our
                    services, enabling us to improve your experience on our
                    platform. Use of Cookies: The cookies we use are designed to
                    help you register for our services and log in to your
                    account. They are not used for tracking purposes or to
                    collect personal data beyond what is necessary for these
                    functions. We do not track user internet usage for other
                    websites.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Children's Privacy
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings is a tool designed for legal professionals
                    and is not intended for use by children. We do not knowingly
                    collect personal data from children under any circumstances.
                    Our commitment to protecting the privacy of our users
                    extends to ensuring that children's privacy is respected and
                    safeguarded.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Policy Updates and Notification
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    Magic Pleadings is dedicated to maintaining a high standard
                    of privacy and will periodically update this privacy policy
                    to reflect any changes in our practices or services.
                    Notification of Updates: Should there be any significant
                    changes to our privacy policy, affected users will be
                    informed via email. This ensures that you are always aware
                    of the information we collect, how we use it, and under what
                    circumstances, if any, we share it. Acknowledgement and
                    Acceptance of Changes: Users will be required to acknowledge
                    and accept any significant changes to the privacy policy
                    before continuing to use our services. This process is
                    typically facilitated through a mechanism such as checking a
                    checkbox, ensuring that users have a clear understanding of
                    our privacy practices.
                  </Typography>

                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ fontFamily: "Recoletabold" }}
                  >
                    Contact Information
                  </Typography>

                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                    }}
                  >
                    If you have any questions or concerns regarding your privacy
                    or any aspect of our privacy policy, please do not hesitate
                    to contact us at support@magicpleadings.ai. Our dedicated
                    support team is committed to addressing your queries and
                    ensuring a secure and protected environment for your
                    personal data.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PrivacyAndPolicy;
