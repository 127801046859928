import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonUI from "../../components/Button";
import Navbar from "../../components/NavBar";

const NotFound = () => {
  const navigate = useNavigate();
  const showErrorToastMessage = (msg) => {
    return toast.error(msg, {
      position: "bottom-right",
    });
  };
  // useEffect(() => {
  //   showErrorToastMessage("Sorry! Page Not Found");
  //   // navigate("/");
  // }, []);
  return (
    <Box variant="div" sx={{ backgroundColor: "#222131" }}>
      <Navbar />
      <Box sx={{ padding: "10%" }}>
        <Box sx={{}}>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontFamily: "Recoletabold",

              color: "lightgray",
            }}
          >
            Unfortunately, the page you're looking for doesn't exist.
          </Typography>
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              fontFamily: "Recoletabold",
              paddingTop: "5%",
              color: "lightgray",
            }}
          >
            404
          </Typography>
          <Box variant="div" sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{
                padding: { xs: "10px 25px", xl: "20px 50px" },
                color: "white",
                mt: "15px",
                fontSize: { xs: "10px", xl: "15px" },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontFamily: "poppins",
                backgroundColor: "#686DFC",
                fontWeight: "800",
                textTransform: "none",
              }}
              onClick={() => navigate("/")}
            >
              Go to Home Page
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
