import { Box, Container, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactUsForm = () => {
  const [disableSignInButton, setDisableSignInButton] = useState(false);
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      address: Yup.string().required("Address is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setDisableSignInButton(true);
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "contactus", values);

        if (response?.data) {
          showSuccessToastMessage("Thank you for contacting us");
          resetForm();
          setDisableSignInButton(false);
        }
      } catch (error) {
        showErrorToastMessage("something was wrong, please try again");

        setDisableSignInButton(false);
      }
    },
  });
  return (
    <>
      <Box sx={{ height: "auto", bgcolor: "#222131", marginTop: "20px" }}>
        <Container
          sx={{
            maxWidth: { xs: "lg", xl: 1400 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={formik.touched.firstName && formik.errors?.firstName}
                margin="normal"
                required
                fullWidth
                id="firstName"
                size="small"
                variant="filled"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                helperText={formik.touched?.firstName && formik.errors?.firstName}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",

                  margin: "0px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={formik.touched.lastName && formik.errors?.lastName}
                margin="normal"
                required
                fullWidth
                id="lastName"
                size="small"
                variant="filled"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                helperText={formik.touched?.lastName && formik.errors?.lastName}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",

                  margin: "0px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={formik.touched.email && formik.errors?.email}
                margin="normal"
                required
                fullWidth
                id="email"
                size="small"
                variant="filled"
                label="Email "
                name="email"
                autoComplete="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                helperText={formik.touched?.email && formik.errors?.email}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",

                  margin: "0px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={formik.touched.address && formik.errors?.address}
                margin="normal"
                required
                fullWidth
                id="address"
                size="small"
                variant="filled"
                label="Address "
                name="address"
                autoComplete="address"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                helperText={formik.touched?.address && formik.errors?.address}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  margin: "0px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                multiline
                error={formik.touched.message && formik.errors?.message}
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="message"
                size="small"
                rows={4}
                label="Message"
                name="message"
                autoComplete="message"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.message}
                helperText={formik.touched?.message && formik.errors?.message}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",

                  width: "100%",
                  margin: "0px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "start",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={disableSignInButton}
                onClick={formik.handleSubmit}
                sx={{
                  fontFamily: "poppins",
                  mt: 2,
                  mb: 2,
                  width: { xs: "100%", sm: "49%" },

                  boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                  "&:hover, &:focus": {
                    boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },

                  ":disabled": {
                    boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },
                  backgroundColor: "#686DFC",
                  fontWeight: "800",
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ContactUsForm;
