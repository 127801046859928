import { Box, Container, Grid, Typography } from "@mui/material";
import Navbar from "../../components/NavBar";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const SamplesDoc = ({ title, imageSrc, imageSrcCSV }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Magic Pleadings | {title}</title>
      </Helmet>
      <Box sx={{ backgroundColor: "#222131" }}>
        <Navbar />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: { sm: "3%" },
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
            <Grid container sx={{ marginY: "auto" }} spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  marginTop: { xs: "100px", sm: "0px" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",

                  height: {
                    sm: "25vh",
                    md: "30vh",
                    lg: "350px",
                    xl: "50vh",
                  },
                  backgroundImage: `url('magic-board-two.png')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: { xs: "100%", sm: "55%", xl: "40%" },
                  backgroundPosition: {
                    xs: "center center",
                    sm: "center center",
                  },
                  textAlign: "center",
                }}
              >
                <Typography
                  color="white"
                  fontWeight="bold"
                  fontSize={{ xs: "40px", sm: "55px", xl: "80px" }}
                  fontFamily="Recoletabold"
                  lineHeight="1"
                  sx={{
                    width: { xs: "90%", sm: "65%" },
                    marginBottom: "50px",
                  }}
                >
                  {title}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{ marginY: "auto", marginTop: { xs: "0px", sm: "-60px" } }}
              >
                {imageSrcCSV ? (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "Recoletabold",
                        color: "white",
                        textAlign: "center",
                        textDecoration: "underline",
                        marginBottom: "5%",
                      }}
                    >
                      Spreadsheet Format
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        height: { xs: "600px", sm: "1000px" },
                        overflow: "scroll",
                        borderRadius: { xs: "20px", sm: "40px" },
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        marginY: "10px",
                      }}
                      component="div"
                    >
                      <Box
                        sx={{
                          marginX: { xs: "0%", sm: "10%" },

                          width: { xs: "100%", sm: "80%" },
                        }}
                        component="img"
                        src={`${imageSrcCSV}?fit=crop&auto=format`}
                      ></Box>
                    </Box>
                  </>
                ) : null}
                <Typography
                  variant="h4"
                  sx={{
                    marginY: "5%",
                    fontFamily: "Recoletabold",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Word Format
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "white",
                    height: { xs: "600px", sm: "1000px" },
                    overflow: "scroll",
                    borderRadius: { xs: "20px", sm: "40px" },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                  component="div"
                >
                  <Box
                    sx={{
                      marginX: { xs: "0%", sm: "10%" },

                      width: { xs: "100%", sm: "80%" },
                    }}
                    component="img"
                    src={`${imageSrc}?fit=crop&auto=format`}
                  ></Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default SamplesDoc;
