import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { State } from "country-state-city";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import PopupModal from "../../components/Modal";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";

const SignUp = () => {
  let stateNames = State.getStatesOfCountry("US").map((value) => value.name);
  const navigate = useNavigate();
  const [content, setContent] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [emailError, setEmailError] = React.useState(null);
  const showErrorToastMessage = (msg) => {
    return toast.error(msg, {
      position: "bottom-right",
    });
  };

  const showSuccessToastMessage = (msg) => {
    return toast.success(msg, {
      position: "bottom-right",
    });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmpassword: "",
      firstName: "",
      lastName: "",
      // lawfirmname: "",
      // billingaddressone: "",
      // billingaddresstwo: "",
      // phone: "",
      // zipcode: "",
      // state: "",
      // city: "",
      termsandconditions: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().min(3, "First name must be at least 3 characters.").required("Required"),
      lastName: Yup.string().min(3, "Last name must be at least 3 characters.").required("Required"),
      // lawfirmname: Yup.string().min(3).required("Required"),
      // billingaddressone: Yup.string().min(10).required("Required"),
      // billingaddresstwo: Yup.string(),
      // phone: Yup.number()
      //   .min(1000000000, "Phone number must be at least 10 digits")
      //   .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().min(5, "Password  must be at least 5 characters.").required("Required"),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "The passwords you entered do not match.")
        .required("Please confirm your password"),
      termsandconditions: Yup.boolean().required("Required").oneOf([true], "You must agree to the terms and conditions"),
      // zipcode: Yup.number()
      //   .min(10000, "Zipcode must be at least 5 characters")
      //   .required("Required"),
      // state: Yup.string().required("Required"),
      // city: Yup.string().required("Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response?.data) {
          showSuccessToastMessage("Registration successful. Please check your email.");

          resetForm();
          setEmailError(null);
          navigate("/");
        }
        // Handle successful response
      } catch (error) {
        setEmailError(error?.response?.data?.emailError);
        console.log("error:", error?.response?.data?.emailError);
        if (error?.response?.data?.emailError) {
          showErrorToastMessage("The user already exists enter another email.");
        } else {
          showErrorToastMessage("Error! please try again");
        }
      }
    },
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowRePassword = () => {
    setShowRePassword((prev) => !prev);
  };

  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
        backgroundColor: "#222131",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridAutoFlow: "column",
        // direction:'column'
        // width:'100%'
      }}
    >
      {/* <ToastContainer /> */}
      <PopupModal text={{ title: "Success!", content: content }} cross={false} handleClose={handleClose} open={open} />
      <Container component="main" maxWidth="sm" sx={{ paddingY: { xs: 8, sm: 4 }, width: "100%" }}>
        <CssBaseline />
        <Box
          sx={{
            // marginTop: { xs: 0, sm: 8 },
            boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
            border: "1px solid #686DFC",
            padding: "30px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#222131",
            color: "white",
          }}
        >
          <Link to={"/"}>
            <Avatar alt="Logo" sx={{ m: 2, width: 65, height: 65, cursor: "pointer" }} src="Magic Pleadings.png" />
          </Link>

          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Recoletabold",
            }}
          >
            Register
          </Typography>

          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={1}></Grid>
            {/* <Grid container spacing={1} sx={{ marginY: "30px" }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  error={
                    formik.touched.billingaddressone &&
                    formik.errors?.billingaddressone
                  }
                  required
                  id="billing-address-one"
                  label="Billing Address Line 1"
                  name="billingaddressone"
                  variant="filled"
                  size="small"
                  autoComplete="billingaddressone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.billingaddressone}
                  helperText={
                    formik.touched?.billingaddressone &&
                    formik.errors?.billingaddressone
                  }
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  error={
                    formik.touched.billingaddresstwo &&
                    formik.errors?.billingaddresstwo
                  }
                  id="billing-address-two"
                  label="Billing Address Line 2"
                  name="billingaddresstwo"
                  variant="filled"
                  size="small"
                  autoComplete="billingaddresstwo"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.billingaddresstwo}
                  helperText={
                    formik.touched?.billingaddresstwo &&
                    formik.errors?.billingaddresstwo
                  }
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <TextField
                  error={formik.touched.city && formik.errors?.city}
                  required
                  id="city"
                  label="City"
                  name="city"
                  variant="filled"
                  size="small"
                  autoComplete="city"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  helperText={formik.touched?.city && formik.errors?.city}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Autocomplete
                  id="state"
                  options={stateNames}
                  getOptionLabel={(option) => option}
                  value={formik.values.state || ""}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("state", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formik.touched.state && formik.errors?.state}
                      required
                      id="state"
                      label="State"
                      name="state"
                      variant="filled"
                      size="small"
                      autoComplete="state"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      helperText={formik.touched?.state && formik.errors?.state}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  error={formik.touched.zipcode && formik.errors?.zipcode}
                  required
                  id="zipcode"
                  label="Zip Code"
                  name="zipcode"
                  variant="filled"
                  size="small"
                  autoComplete="zipcode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.zipcode}
                  helperText={formik.touched?.zipcode && formik.errors?.zipcode}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={1.5} sx={{ marginY: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formik.touched.firstName && formik.errors?.firstName}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  id="firstName"
                  label="First Name"
                  variant="filled"
                  size="small"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  helperText={formik.touched?.firstName && formik.errors?.firstName}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formik.touched.lastName && formik.errors?.lastName}
                  required
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  variant="filled"
                  size="small"
                  autoComplete="family-name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  helperText={formik.touched?.lastName && formik.errors?.lastName}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  error={formik.touched.phone && formik.errors?.phone}
                  required
                  id="phone"
                  label="Cell Phone"
                  name="phone"
                  variant="filled"
                  size="small"
                  autoComplete="phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  helperText={formik.touched?.phone && formik.errors?.phone}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  error={(formik.touched.email && formik.errors?.email) || emailError}
                  required
                  id="email"
                  label="Email Address"
                  name="email"
                  variant="filled"
                  autoComplete="email"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  helperText={(formik.touched?.email && formik.errors?.email) || emailError}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={formik.touched.password && formik.errors?.password}
                  required
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="filled"
                  id="password"
                  size="small"
                  autoComplete="new-password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  helperText={formik.touched?.password && formik.errors?.password}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <Visibility sx={{ color: "black", width: "20px" }} /> : <VisibilityOff sx={{ color: "black", width: "20px" }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={formik.touched.confirmpassword && formik.errors?.confirmpassword}
                  required
                  name="confirmpassword"
                  label="Re-write Password"
                  type={showRePassword ? " type" : "password"}
                  variant="filled"
                  id="confirmpassword"
                  autoComplete="confirm-password"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.confirmpassword}
                  helperText={formik.touched?.confirmpassword && formik.errors?.confirmpassword}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowRePassword} onMouseDown={handleMouseDownRePassword} edge="end">
                          {showRePassword ? <Visibility sx={{ color: "black", width: "20px" }} /> : <VisibilityOff sx={{ color: "black", width: "20px" }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox sx={{ color: "white" }} checked={formik.values.termsandconditions} color="primary" name="termsandconditions" onBlur={formik.handleBlur} onChange={formik.handleChange} />
                  }
                  label={
                    <span style={{ fontFamily: "poppins" }}>
                      I have read and agree to{" "}
                      <a href="/terms" target="_blank" style={{ color: "#686DFC" }}>
                        terms and conditions
                      </a>
                    </span>
                  }
                />
                <FormHelperText sx={{ color: "#D32F2F", fontFamily: "poppins" }}>{formik.errors?.termsandconditions}</FormHelperText>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "100%",
                mt: 1.5,
                mb: 2,
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },

                backgroundColor: "#686DFC",
                fontWeight: "800",
                fontFamily: "poppins",
              }}
            >
              Register
            </Button>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  fontSize: { xs: "16px", sm: "16px" },
                  fontFamily: "poppins",
                }}
              >
                Already have an account?
                <Link to={"/login"} style={{ color: "white", fontSize: "16px" }} variant="body2">
                  {" Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SignUp;
