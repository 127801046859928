import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ListCardDoc = ({ id, heading, title, linkSrc, content }) => {
  return (
    <Box
      sx={{
        marginLeft: { xs: "4%", sm: "0%" },
        marginRight: { xs: "6%", sm: "0%" },
      }}
    >
      {id == 1 ? (
        <Grid container spacing={2}>
          <Container
            sx={{
              maxWidth: { xs: "md", xl: 1400 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                sx={{ color: "#686DFC", fontFamily: "Recoletabold" }}
              >
                {heading}
              </Typography>
            </Grid>
          </Container>
        </Grid>
      ) : (
        ""
      )}

      <Grid
        container
        spacing={2}
        sx={{
          marginY: "30px",
          marginX: { xs: "auto", sm: "-2%" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Container
          sx={{
            maxWidth: { xs: "md", xl: 1400 },
            display: { xs: "grid", sm: "flex" },
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={4}
            sx={{
              border: "5px solid",
              borderColor: "#686DFC",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: { xs: "10px", sm: "0px" },
              borderBottomLeftRadius: { xs: "0px", sm: "10px" },
              height: { xs: "auto", sm: "180px" },
              overflowY: "scroll",
              scrollbarWidth: "none" /* Firefox */,
              "-ms-overflow-style": "none" /* IE and Edge */,
              "&::-webkit-scrollbar": {
                display: "none" /* Safari and Chrome */,
              },              padding: { xs: "5%", sm: "15px" },
              backgroundColor: "#222131",
              display: "flex", // Set display to flex
              alignItems: "center", // Center items vertically
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Typography
              sx={{ fontFamily: "Recoletabold", fontSize: "26px" }}
              variant="h5"
            >
              {title}
            </Typography>
            <Link to={linkSrc}>
              <Typography
                sx={{
                  marginTop: "10px",
                  fontFamily: "poppins",
                  fontWeight: 600,
                  fontSize: "15px",
                  textDecoration: "underline",
                  color: "white",
                }}
              >
                See Sample Document
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            container
            direction="row"
            xs={12}
            sm={8}
            sx={{
              borderTop: { xs: "0px solid", sm: "5px solid #686DFC" },
              borderBottom: "5px solid",
              borderRight: "5px solid",
              borderLeft: { xs: "5px solid #686DFC", sm: "0px solid" },
              borderColor: "#686DFC",
              borderTopRightRadius: { xs: "0px", sm: "10px" },
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: { xs: "10px", sm: "0px" },
              height: { xs: "auto", sm: "180px" },
              overflowY: "scroll",
              scrollbarWidth: "none" /* Firefox */,
              "-ms-overflow-style": "none" /* IE and Edge */,
              "&::-webkit-scrollbar": {
                display: "none" /* Safari and Chrome */,
              },
              padding: "15px",
              backgroundColor: "#222131",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                display: "flex",
                alignItems: "center",
              }}
            >
              {content}
            </Typography>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default ListCardDoc;
