import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, action) => {
      return action.payload;
    },
    clearUser: () => initialState,

    setCheckoutData: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          checkoutData: action?.payload,
        },
      };
    },
    clearCheckoutData: (state) => {
      const { checkoutData, ...newUserData } = state.userData;

      return {
        ...state,
        userData: newUserData,
      };
    },
  },
});

const { setUser, clearUser, setCheckoutData, clearCheckoutData } =
  userSlice.actions;
const reducer = userSlice.reducer;

const slices = {
  setUser: setUser,
  clearUser: clearUser,
  setCheckoutData: setCheckoutData,
  clearCheckoutData: clearCheckoutData,
  reducer: reducer,
};

export default slices;
