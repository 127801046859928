import React, { useState, useRef, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemText, Container, Box, ListItemButton, Collapse, Stack, Button } from "@mui/material";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import DropDown from "../DropDown";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import PopupModal from "../Modal/index.js";
import LogoutIcon from "@mui/icons-material/Logout";
import SampleDropDown from "../SamplesDropDown";
import { useAuth } from "../../App.js";
import SamplesData from "../../pages/Samples/SamplesData.js";
import IframeData from "../../pages/Iframes/IframeData.js";
import { toast } from "react-toastify";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const Navbar = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openStudents, setOpenStudents] = useState(false);
  const [openPleadings, setOpenPleadings] = useState(false);
  const [openMedical, setOpenMedical] = useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpen = () => setOpenConfirm(true);
  const handleClose = () => setOpenConfirm(false);
  const buttonRef = useRef(null);
  const buttonStudentRef = useRef(null);
  const buttonPleadingsRef = useRef(null);
  const buttonMedicalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setOpen(false);
      }

      if (buttonStudentRef.current && !buttonStudentRef.current.contains(event.target)) {
        setOpenStudents(false);
      }

      if (buttonPleadingsRef.current && !buttonPleadingsRef.current.contains(event.target)) {
        setOpenPleadings(false);
      }

      if (buttonMedicalRef.current && !buttonMedicalRef.current.contains(event.target)) {
        setOpenMedical(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSampleClick = (event) => {
    event.stopPropagation();
    handleClick();
  };

  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleStudents = (event) => {
    event.stopPropagation();
    setOpenStudents(!openStudents);
    setOpenPleadings(false);
    setOpenMedical(false);
  };
  const handlePleadings = (event) => {
    event.stopPropagation();
    setOpenPleadings(!openPleadings);
    setOpenStudents(false);
    setOpenMedical(false);
  };
  const handleMedical = (event) => {
    event.stopPropagation();
    setOpenMedical(!openMedical);
    setOpenPleadings(false);
    setOpenStudents(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
    console.log("conosle");
  };
  const handleLogoutConfirm = () => {
    logout();
    handleClose();
    showSuccessToastMessage("You have been logged out successfully!");
    setDrawerOpen(false);
    navigate("/");
  };

  const logoutAlert = () => {
    handleOpen();
  };

  const menuItems = [
    { text: "Home", link: "/" },
    { text: "About", link: "/about" },

    // Add more menu items as needed
  ];

  return (
    <Container
      sx={{
        maxWidth: { xs: 1100, xl: 1400 },
        paddingBottom: { xs: "50px", sm: "0px" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <PopupModal
        text={{
          title: "Are you sure?",
          content: "You want to logout?",
        }}
        cross={true}
        handleClose={handleClose}
        open={openConfirm}
        handleLogoutConfirm={handleLogoutConfirm}
      />
      <AppBar
        position="static"
        sx={{
          background: "transparent",
          boxShadow: "none",
          width: "100%",
        }}
      >
        <Toolbar sx={{ marginRight: { xs: "0%", md: "-3%", display: "flex" } }}>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            <Link
              to="/"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "white",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: { xs: 100, sm: 100, xl: 130 },
                  width: { xs: 100, sm: 120, xl: 160 },
                  // width:'120px',
                  // width:'150px',
                  marginLeft: { xs: "20%", sm: "-10%" },
                  marginRight: { xs: "0%", sm: "-5%" },
                }}
                alt={menuItems.title}
                src={`../../../Magic Pleadings.png?w=164&h=164&fit=crop&auto=format`}
              />

              <Typography
                variant="h6"
                component="div"
                sx={{
                  position: { xs: "absolute", sm: "relative" },
                  bottom: "0",
                  top: "105%",
                  marginTop: "1%",
                  width: { xs: "230px", sm: "100%" },
                  height: { xs: 20, sm: 100, xl: 130 },
                  // display: { xs: "none", sm: "flex" },
                  display: "flex",
                  alignItems: "center",
                  // flexGrow: 1,
                  fontSize: { xs: "30px", xl: "40px" },
                  fontFamily: "Recoletabold",
                }}
              >
                Magic Pleadings
              </Typography>
            </Link>
          </Box>


          {/* this is icon humbuger  */}
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "50%",
            }}
          >
            <Stack direction={isAuthenticated ? "row" : "column"} spacing={!isAuthenticated ? 1 : -1.5}>
              <IconButton
                // aria-label="Large sizes"
                edge="end"
                color="inherit"
                aria-label="menu icon"
                sx={{
                  zIndex: "999",
                  display: { md: "block", lg: "none" },
                }}
                onClick={handleDrawerOpen}
              >
                <MenuIcon
                  sx={{
                    fontSize: "30px",
                    display: { md: "block", lg: "none" },
                  }}
                />
              </IconButton>

              <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerOpen}>
                <List
                  sx={{
                    backgroundColor: "#EDEDF2",
                    height: "100vh",
                    width: "350px",
                  }}
                >
                  <ListItemButton
                    edge="end"
                    color="inherit"
                    aria-label="menu icon"
                    sx={{
                      float: "right",
                      zIndex: "999",
                      display: { md: "block", lg: "none" },
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <CloseIcon />
                  </ListItemButton>

                  <Box component="div" sx={{ marginTop: "50px" }}>
                    <Link
                      to={`/`}
                      style={{
                        fontSize: "40px",
                        textDecoration: "none",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                          color: "gray",
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "300",
                          margin: { xs: "2px 30px" },
                          textAlign: "center",
                          transition: "background-color 0.3s ease-in-out",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            color: "gray",
                          },
                          color: "black",
                        }}
                        onClick={handleDrawerOpen}
                      >
                        <HomeOutlinedIcon
                          sx={{
                            marginLeft: "0px",
                            marginRight: "-140px",
                            color: "black",
                          }}
                        />
                        <ListItemText
                          disableTypography
                          sx={{
                            fontSize: "17px",
                            fontFamily: "poppins",
                            fontWeight: 500,
                          }}
                          primary="Home"
                        />
                      </ListItemButton>
                    </Link>

                    {isAuthenticated && (
                      <>
                        <ListItemButton
                          sx={{
                            marginBottom: "10px",
                            fontFamily: "poppins",
                            fontWeight: "300",
                            margin: { xs: "2px 30px" },
                            textAlign: "center",
                            transition: "background-color 0.3s ease-in-out",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              color: "gray",
                            },
                            color: "black",
                          }}
                          onClick={logoutAlert}
                        >
                          <LogoutIcon
                            sx={{
                              marginLeft: "0px",
                              marginRight: "-140px",
                              color: "black",
                            }}
                          />
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Logout"
                          />
                        </ListItemButton>

                        <Link to={"/subscriptions"} style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              fontFamily: "poppins",
                              margin: "10px 20px",
                              border: "1px dotted",
                              borderRadius: "10px",
                              textAlign: "center",
                              color: "white",
                              boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                              "&:hover, &:focus": {
                                boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                                backgroundColor: "#686DFC",
                              },
                              backgroundColor: "#686DFC",
                            }}
                          >
                            <ListItemText
                              disableTypography
                              sx={{
                                fontSize: "17px",
                                fontFamily: "poppins",
                                fontWeight: 500,
                              }}
                              primary="Subscriptions"
                            />
                          </ListItemButton>
                        </Link>
                      </>
                    )}
                    {!isAuthenticated && (
                      <Link
                        to={`/login`}
                        style={{
                          fontSize: "40px",
                          textDecoration: "none",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            color: "gray",
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            fontFamily: "poppins",
                            fontWeight: "300",
                            margin: { xs: "2px 30px" },
                            textAlign: "center",
                            transition: "background-color 0.3s ease-in-out",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              color: "gray",
                            },
                            color: "black",
                          }}
                          onClick={handleDrawerOpen}
                        >
                          <LockPersonOutlinedIcon
                            sx={{
                              marginLeft: "0px",
                              marginRight: "-140px",
                              color: "black",
                            }}
                          />
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Login"
                          />
                        </ListItemButton>
                      </Link>
                    )}
                    {!isAuthenticated && (
                      <Link
                        to={`/register`}
                        style={{
                          fontSize: "40px",
                          textDecoration: "none",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            color: "gray",
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            fontFamily: "poppins",
                            fontWeight: "300",
                            margin: { xs: "2px 30px" },
                            textAlign: "center",
                            transition: "background-color 0.3s ease-in-out",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              color: "gray",
                            },
                            color: "black",
                          }}
                          onClick={handleDrawerOpen}
                        >
                          <PersonAddAltIcon
                            sx={{
                              marginLeft: "4px",
                              marginRight: "-75px",
                              color: "black",
                            }}
                          />
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Try the Magic"
                          />
                        </ListItemButton>
                      </Link>
                    )}

                    {!isAuthenticated && (
                      <>
                        <ListItemButton
                          sx={{
                            fontFamily: "poppins",
                            margin: "10px 20px",
                            border: "1px dotted",

                            borderRadius: open ? "0px" : "10px",

                            textAlign: "center",
                            color: open ? "#6a1b9a" : "white",

                            boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                            "&:hover, &:focus": {
                              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                              backgroundColor: open ? "white" : "#686DFC",
                            },
                            backgroundColor: open ? "white" : "#686DFC",
                          }}
                          ref={buttonRef}
                          onClick={handleSampleClick}
                        >
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Samples"
                          />
                          {open ? <ArrowDropUpIcon sx={{ color: "#6a1b9a" }} /> : <ArrowDropDownIcon sx={{ color: "white" }} />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {SamplesData.map((doc) => {
                              return (
                                <Link
                                  key={doc.id}
                                  to={`/samples/${doc.id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    "&:hover": {
                                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                                      color: "gray",
                                    },
                                  }}
                                  onClick={handleDrawerOpen}
                                >
                                  <ListItemButton
                                    sx={{
                                      fontFamily: "poppins",
                                      pl: 4,
                                      borderBottom: "1px solid",
                                      color: "white",
                                      marginX: "20px",
                                      px: "12px",
                                      boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                                      "&:hover": {
                                        boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                                        backgroundColor: "white",
                                        color: "#6a1b9a",
                                      },
                                      backgroundColor: "#686DFC",
                                    }}
                                  >
                                    <ListItemText
                                      disableTypography
                                      sx={{
                                        fontSize: "15px",
                                      }}
                                      primary={`${doc.title}`}
                                    />
                                  </ListItemButton>
                                </Link>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    )}

                    {/* after login  */}
                    {/* Students */}
                    {isAuthenticated && (
                      <>
                        <ListItemButton
                          sx={{
                            fontFamily: "poppins",
                            margin: "10px 20px",
                            border: "1px dotted",

                            borderRadius: openStudents ? "0px" : "10px",

                            textAlign: "center",
                            color: openStudents ? "#6a1b9a" : "white",

                            boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                            "&:hover, &:focus": {
                              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                              backgroundColor: openStudents ? "white" : "#686DFC",
                            },
                            backgroundColor: openStudents ? "white" : "#686DFC",
                          }}
                          ref={buttonStudentRef}
                          onClick={handleStudents}
                        >
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Students"
                          />
                          {openStudents ? <ArrowDropUpIcon sx={{ color: "#6a1b9a" }} /> : <ArrowDropDownIcon sx={{ color: "white" }} />}
                        </ListItemButton>
                        <Collapse in={openStudents} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {IframeData.IframeStudentData.map((doc) => {
                              return (
                                <Link
                                  key={doc.id}
                                  to={`/students/${doc.id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    "&:hover, &:focus": {
                                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                                      color: "gray",
                                    },
                                  }}
                                  onClick={handleDrawerOpen}
                                >
                                  <ListItemButton
                                    sx={{
                                      fontFamily: "poppins",
                                      pl: 4,
                                      borderBottom: "1px solid",
                                      color: "white",
                                      marginTop: "-9px",
                                      marginX: "20px",
                                      px: "12px",
                                      boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                                      "&:hover": {
                                        boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                                        backgroundColor: "white",
                                        color: "#6a1b9a",
                                      },
                                      backgroundColor: "#686DFC",
                                    }}
                                    onClick={handleStudents}
                                  >
                                    <ListItemText
                                      disableTypography
                                      sx={{
                                        fontSize: "15px",
                                      }}
                                      primary={`${doc.title}`}
                                    />
                                  </ListItemButton>
                                </Link>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    )}

                    {/* Pleadings */}
                    {isAuthenticated && (
                      <>
                        <ListItemButton
                          sx={{
                            fontFamily: "poppins",
                            margin: "10px 20px",
                            border: "1px dotted",
                            borderRadius: openPleadings ? "0px" : "10px",
                            textAlign: "center",
                            color: openPleadings ? "#6a1b9a" : "white",
                            boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                            "&:hover, &:focus": {
                              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                              backgroundColor: openPleadings ? "white" : "#686DFC",
                            },
                            backgroundColor: openPleadings ? "white" : "#686DFC",
                          }}
                          ref={buttonPleadingsRef}
                          onClick={handlePleadings}
                        >
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Pleadings"
                          />
                          {openPleadings ? <ArrowDropUpIcon sx={{ color: "#6a1b9a" }} /> : <ArrowDropDownIcon sx={{ color: "white" }} />}
                        </ListItemButton>
                        <Collapse in={openPleadings} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {IframeData.IframePleadingsData.map((doc) => {
                              return (
                                <Link
                                  key={doc.id}
                                  to={`/pleadings/${doc.id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    "&:hover": {
                                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                                      color: "gray",
                                    },
                                  }}
                                  onClick={handleDrawerOpen}
                                >
                                  <ListItemButton
                                    sx={{
                                      fontFamily: "poppins",
                                      pl: 4,
                                      borderBottom: "1px solid",
                                      color: "white",
                                      marginTop: "-9px",
                                      marginX: "20px",
                                      px: "12px",
                                      boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                                      "&:hover": {
                                        boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                                        backgroundColor: "white",
                                        color: "#6a1b9a",
                                      },
                                      backgroundColor: "#686DFC",
                                    }}
                                    onClick={handlePleadings}
                                  >
                                    <ListItemText
                                      disableTypography
                                      sx={{
                                        fontSize: "15px",
                                      }}
                                      primary={`${doc.title}`}
                                    />
                                  </ListItemButton>
                                </Link>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    )}

                    {/* Medical */}
                    {isAuthenticated && (
                      <>
                        <ListItemButton
                          sx={{
                            fontFamily: "poppins",
                            margin: "10px 20px",
                            border: "1px dotted",
                            borderRadius: openMedical ? "0px" : "10px",
                            textAlign: "center",
                            color: openMedical ? "#6a1b9a" : "white",
                            boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                            "&:hover, &:focus": {
                              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                              backgroundColor: openMedical ? "white" : "#686DFC",
                            },
                            backgroundColor: openMedical ? "white" : "#686DFC",
                          }}
                          ref={buttonMedicalRef}
                          onClick={handleMedical}
                        >
                          <ListItemText
                            disableTypography
                            sx={{
                              fontSize: "17px",
                              fontFamily: "poppins",
                              fontWeight: 500,
                            }}
                            primary="Medicals"
                          />
                          {openMedical ? <ArrowDropUpIcon sx={{ color: "#6a1b9a" }} /> : <ArrowDropDownIcon sx={{ color: "white" }} />}
                        </ListItemButton>
                        <Collapse in={openMedical} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {IframeData.IframeMedicalData.map((doc) => {
                              return (
                                <Link
                                  key={doc.id}
                                  to={`/medical/${doc.id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    "&:hover": {
                                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                                      color: "gray",
                                    },
                                  }}
                                  onClick={handleDrawerOpen}
                                >
                                  <ListItemButton
                                    sx={{
                                      fontFamily: "poppins",
                                      pl: 4,
                                      borderBottom: "1px solid",
                                      color: "white",
                                      marginTop: "-9px",
                                      marginX: "20px",
                                      px: "12px",
                                      boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                                      "&:hover": {
                                        boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                                        backgroundColor: "white",
                                        color: "#6a1b9a",
                                      },
                                      backgroundColor: "#686DFC",
                                    }}
                                    onClick={handleMedical}
                                  >
                                    <ListItemText
                                      disableTypography
                                      sx={{
                                        fontSize: "15px",
                                      }}
                                      primary={`${doc.title}`}
                                    />
                                  </ListItemButton>
                                </Link>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </Box>
                </List>
              </Drawer>

              {!isAuthenticated && <SampleDropDown />}
              {isAuthenticated && <DropDown logoutAlert={logoutAlert} />}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Navbar;
