import { Button } from "@mui/material";

const ButtonUI = ({ text, handleClick }) => {
  return (
    <Button
      sx={{
        padding: { xs: "10px 25px", xl: "20px 50px" },
        color: "white",
        mt: "15px",
        fontSize: { xs: "10px", xl: "15px" },
        boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
        "&:hover, &:focus": {
          boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
          backgroundColor: "white",
          color: "#6a1b9a",
        },
        fontFamily: "poppins",
        backgroundColor: "#686DFC",
        fontWeight: "800",
        textTransform: "none",
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default ButtonUI;
