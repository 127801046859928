import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar from "../components/NavBar";
const defaultTheme = createTheme();

const Terms = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box component="div" sx={{ backgroundColor: "#222131" }}>
      <Navbar />
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            minHeight: "100vh",
            maxHeight: "100%",
            backgroundColor: "#222131",
          }}
        >
          <Container
            component="main"
            maxWidth="mx"
            sx={{
              paddingY: 6,
              maxWidth: { xs: "md", xl: 1410 },
            }}
          >
            <Box component="div">
              <Typography
                component="h1"
                variant="h3"
                sx={{
                  fontFamily: "Recoletabold",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Terms & <span style={{ color: "#686DFC" }}>Conditions</span>
              </Typography>
              <Typography
                component="p"
                variant="p"
                sx={{
                  marginY: "20px",
                  paddingX: { xs: "20px", sm: "100px" },
                  fontFamily: "poppins",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Thank you for choosing Magic Pleadings! Our Terms and Conditions
                outline the rules and guidelines for using our services. By
                accessing or using Magic Pleadings, you agree to abide by these
                terms and conditions.
              </Typography>
            </Box>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 4,
                boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
                border: "1px solid #686DFC",
                padding: "30px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#222131",
                color: "white",
              }}
            >
              <Box component="div" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "justify",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Your use of Magic Pleadings, including the testing of any
                      pre-release software or documents ("Beta Services"), is
                      regulated by the following terms and conditions ("Magic
                      Pleadings Terms of Service"). Unless explicitly modified
                      hereunder, the comprehensive Magic Pleadings Services
                      Terms and Conditions applicable to your region are
                      applicable and are incorporated by reference herein, with
                      defined terms adopting the meanings ascribed in the Magic
                      Pleadings Services Terms and Conditions, except as
                      otherwise specified in this document. For the purposes of
                      these Magic Pleadings Terms of Service, the term "Licensed
                      Service" includes the Beta Services.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Scope and Acceptance
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings is a sophisticated web platform designed
                      to revolutionize the process of creating legal
                      documentation. By leveraging advanced artificial
                      intelligence technologies, Magic Pleadings reviews
                      information and data submitted by our users to automate
                      the creation of necessary legal documents, thereby
                      simplifying the legal preparation process for our users.
                      Intended Users: The services provided by Magic Pleadings
                      are specifically tailored for attorneys, law firms, and
                      paralegals seeking efficiency and accuracy in document
                      preparation. Our platform aims to be a valuable tool in
                      their legal practice, enhancing productivity and
                      facilitating case management. Acceptance of Terms: To gain
                      access to the services offered by Magic Pleadings, users
                      must first agree to our Terms and Conditions. This
                      agreement is secured during the registration process,
                      where users are required to review the Terms and
                      Conditions and signify their acceptance by proceeding with
                      the registration. By accepting these terms, users
                      acknowledge they have read, understood, and agreed to be
                      bound by them.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      License Terms
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings grants a unique license to its users,
                      tailored to the needs and realities of legal document
                      creation. Copyright Ownership: In recognition of the
                      professional nature of the services we provide, any
                      copyrights arising from the creation of legal documents
                      using Magic Pleadings are assigned to the registered user.
                      This policy ensures that attorneys, law firms, and
                      paralegals retain full ownership of the documents they
                      create through our platform, reinforcing their rights to
                      the intellectual property generated during the use of our
                      services. Usage Restrictions: Magic Pleadings is dedicated
                      to providing a versatile tool designed to streamline the
                      legal document creation process. As such, we do not impose
                      restrictions on the use of our platform. Users are free to
                      utilize Magic Pleadings for any lawful purpose, including
                      but not limited to commercial and non-commercial use. We
                      encourage our users to employ our services in a manner
                      that best supports their professional requirements and
                      goals. By accepting these license terms, users agree to
                      leverage the Magic Pleadings platform within the scope of
                      lawful activities, adhering to all relevant copyright laws
                      and maintaining the highest standards of professional
                      conduct.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      User Obligations
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings prioritizes the integrity and legality of
                      the document creation process. Users of our platform carry
                      certain responsibilities to ensure the functionality and
                      effectiveness of the services provided. Accuracy of
                      Information: When using Magic Pleadings, it is imperative
                      that users input accurate and complete information. The
                      accuracy of the documents generated by our platform is
                      directly dependent on the preciseness of the data provided
                      by the user. Misrepresentation or inaccuracies can lead to
                      errors in the final documents, which may affect their
                      legality or usability. Compliance with Laws: Users are
                      obligated to comply with all applicable laws and
                      regulations concerning the handling of sealed,
                      confidential, or sensitive information. Given that data
                      input into Magic Pleadings may be shared with third-party
                      AI partners for processing, users must be diligent in
                      ensuring that their use of our platform does not
                      contravene legal standards related to information privacy
                      and confidentiality. Contact Information: To facilitate
                      seamless communication and the delivery of documents
                      created using Magic Pleadings, users must maintain
                      accurate and up-to-date contact information at all times.
                      Failure to do so may result in a disruption of service and
                      delay in the receipt of documents. By agreeing to these
                      Terms and Conditions, users affirm their commitment to
                      meeting these obligations, acknowledging the importance of
                      accuracy, compliance, and transparency in the utilization
                      of Magic Pleadings.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Content and Intellectual Property
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings recognizes the importance of intellectual
                      property rights, especially in the legal field where the
                      creation and use of documents are a central part of the
                      profession. Ownership of Created Content: In alignment
                      with our commitment to empower our users, Magic Pleadings
                      explicitly transfers any copyrights that may arise from
                      the generation of pleadings or documents through our
                      platform to the user. This transfer of rights occurs in
                      exchange for the payment for our services, ensuring that
                      users retain full ownership of the output they produce
                      using Magic Pleadings. Accordingly, users can rest assured
                      that they have complete control and exclusive ownership of
                      the documents they generate, free from any claim by Magic
                      Pleadings. Claim Over Documents: Magic Pleadings does not
                      claim any ownership over documents created by its users.
                      Our role is to facilitate the creation of these documents
                      through the use of advanced AI and automation
                      technologies, beyond which we hold no rights to the
                      content generated by our users. This policy ensures that
                      the intellectual property rights of the documents remain
                      exclusively with the user, providing clarity and security
                      for attorneys, law firms, and paralegals who utilize our
                      services. By leveraging Magic Pleadings for document
                      creation, users acknowledge their responsibility and
                      rights concerning the intellectual property generated.
                      This understanding forms a critical foundation for the
                      trust between Magic Pleadings and its users, protecting
                      the professional integrity and legal rights of all parties
                      involved.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Limitations and Liability
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings employs cutting-edge artificial
                      intelligence to streamline the document creation process
                      for legal professionals. However, as AI technology
                      continues to develop, there may be instances where the
                      output does not meet the expected standards of accuracy.
                      Acknowledgement of Potential Inaccuracies: Users hereby
                      acknowledge that, due to the emerging nature of AI
                      technologies, errors in document generation may occur,
                      including inaccuracies in legal citations, factual
                      statements, and statements of law. By using Magic
                      Pleadings, users accept the responsibility to
                      independently verify all generated content for accuracy
                      and fairness before submission to any court or legal body.
                      Hold Harmless Agreement: Users agree to hold Magic
                      Pleadings harmless from any liabilities, losses, or
                      damages arising from inaccuracies in the documents
                      generated through our platform. It is the user's,
                      particularly the attorney’s, responsibility to ensure that
                      all facts, laws, and legal citations included in documents
                      are accurate and are fair representations to the court or
                      any other legal entity.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Disclaimers:
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Accuracy and Reliability: Magic Pleadings expressly
                      disclaims any warranty regarding the accuracy,
                      reliability, or suitability of the services provided.
                      Users acknowledge that reliance on any documents created
                      by our platform is strictly at their own risk. Emerging
                      Technology: Given the innovative and evolving nature of AI
                      in the legal field, Magic Pleadings cannot guarantee that
                      the service will meet all user expectations or that the
                      usage will be uninterrupted, timely, secure, or
                      error-free. Professional Judgment: Users, especially
                      licensed legal professionals, must exercise their
                      professional judgment in reviewing and amending documents
                      generated by Magic Pleadings. The platform should be seen
                      as a tool to assist in document preparation rather than a
                      definitive legal advisor.By agreeing to these Terms and
                      Conditions, users confirm their understanding of the
                      limitations and liability associated with using Magic
                      Pleadings and agree to the outlined conditions of use.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Data Use and Privacy
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings is dedicated to maintaining the
                      confidentiality and security of our users' data. This
                      section outlines how we use and protect the data collected
                      from our users. Privacy and Data Policy Acceptance: By
                      using Magic Pleadings, users agree to our Privacy and Data
                      Policy, which comprehensively states our practices
                      regarding data collection, use, and protection. We
                      encourage users to review this policy in detail to
                      understand their rights and our obligations. The
                      acceptance of the Terms and Conditions includes
                      acknowledgment and agreement to abide by the Privacy and
                      Data Policy as stated by Magic Pleadings. Data Sharing
                      with Third Parties: In the course of providing our
                      document generation services, it is necessary for us to
                      share data and information input by users with third-party
                      AI data processing companies. This collaboration is
                      essential for the operation of our AI-driven document
                      creation features. Partnership Transparency: We only
                      partner with AI data processing companies that adhere to
                      stringent privacy and security standards, ensuring that
                      your data is handled responsibly. Purpose of Data Sharing:
                      The data shared with third-party partners is solely for
                      the purpose of processing requests made through Magic
                      Pleadings and is not used for any other purpose without
                      your explicit consent. By utilizing Magic Pleadings, users
                      consent to the sharing of their data with these third
                      parties as an integral part of accessing our services. We
                      are committed to transparency regarding our data use
                      practices and to providing users with control over their
                      personal information.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Termination and Suspension
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings reserves the right to suspend or terminate
                      access to our services under certain conditions, ensuring
                      the integrity and lawful use of our platform. Conditions
                      for Suspension or Termination: Access to Magic Pleadings
                      may be suspended or terminated if a user is found to be:
                      Purposefully or maliciously utilizing the system in a
                      manner that is non-legal or contrary to the intended
                      purpose of creating lawful and accurate legal documents.
                      Violating any terms outlined in our Terms and Conditions,
                      including but not limited to misuse of the platform’s
                      resources or infringement of copyright. Handling of
                      Personal Information Post-Termination: In the event of
                      account termination, whether by the user's request or by
                      Magic Pleadings due to a breach of terms, Magic Pleadings
                      retains the right to keep and store personal information
                      as previously agreed to under our Privacy and Data Policy.
                      This retention is in compliance with legal requirements
                      and for audit purposes, ensuring the obligations of both
                      parties are maintained post-termination. Data Retention:
                      The specifics of data retention, including the types of
                      data retained and the duration, are detailed in our
                      Privacy and Data Policy. Users are encouraged to
                      familiarize themselves with these details to understand
                      how their data is handled. By accepting these Terms and
                      Conditions, users acknowledge and agree to the potential
                      for suspension or termination of access, along with the
                      conditions under which personal information may be
                      retained by Magic Pleadings post-termination.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Amendments to the Terms and Conditions
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings reserves the right to update or modify
                      these Terms and Conditions at any time to reflect changes
                      in our services, legal and regulatory requirements, or any
                      other aspects influencing the operation of our platform.
                      Notification of Amendments: In the event of a material
                      change to these Terms and Conditions, Magic Pleadings will
                      notify users via email to ensure they are informed of such
                      amendments. It is the responsibility of the users to keep
                      their contact information up to date to receive these
                      notifications promptly. Acceptance of Updated Terms:
                      Continued use of Magic Pleadings after any updates to the
                      Terms and Conditions have been notified and published will
                      be considered as acceptance of those changes. Users are
                      encouraged to review the updated Terms and Conditions to
                      understand their rights and obligations under the revised
                      policy. If a user does not agree to the updated Terms and
                      Conditions, they must cease using Magic Pleadings'
                      services immediately. Continued usage of the platform
                      implies consent to the updated terms. By enabling clear
                      communication regarding amendments and requiring user
                      consent to the updated terms, Magic Pleadings aims to
                      ensure transparency and mutual agreement on the conditions
                      governing the use of our services.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Dispute Resolution
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      Magic Pleadings is committed to resolving disputes fairly
                      and efficiently. If disputes arise between Magic Pleadings
                      and its users related to the services provided, or any
                      aspects of the Terms and Conditions, the following process
                      shall apply: Jurisdiction: The 19th Judicial District
                      Court for the Parish of East Baton Rouge, State of
                      Louisiana shall be the exclusive jurisdiction for any
                      disputes or claims arising out of or in connection with
                      the Terms and Conditions, or the use of Magic Pleadings.
                      We encourage users to contact Magic Pleadings directly to
                      seek a resolution before initiating any legal action. Our
                      aim is to resolve disputes amicably and to the
                      satisfaction of all parties involved.
                    </Typography>

                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ fontFamily: "Recoletabold" }}
                    >
                      Contact Information
                    </Typography>

                    <Typography
                      component="p"
                      variant="body2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "14px", xl: "20px" },
                      }}
                    >
                      For any questions or concerns related to the Terms and
                      Conditions, or any other inquiries regarding Magic
                      Pleadings, users are encouraged to reach out via email to
                      support@magicpleadings.ai. Our dedicated support team is
                      available to assist you with your inquiries and ensure
                      your experience with Magic Pleadings is positive and
                      productive.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default Terms;
