import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";

function createData(name, Email, Role) {
  return { name, Email, Role };
}

const rows = [
  createData("Leslie Alexander", "alexandor@gmail.com", "Editor"),
  createData("Leslie Alexander", "alexandor@gmail.com", "Editor"),
  createData("Leslie Alexander", "alexandor@gmail.com", "Editor"),
  createData("Leslie Alexander", "alexandor@gmail.com", "Editor"),
  createData("Leslie Alexander", "alexandor@gmail.com", "Editor"),
];

const BasicTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#67687E" }}>
          <TableRow>
            <TableCell align="left" sx={{ color: "white" }}>
              #
            </TableCell>
            <TableCell
              align="left"
              sx={{ color: "white", fontFamily: "poppins" }}
            >
              Name
            </TableCell>
            <TableCell
              align="left"
              sx={{ color: "white", fontFamily: "poppins" }}
            >
              Email
            </TableCell>
            <TableCell
              align="left"
              sx={{ color: "white", fontFamily: "poppins" }}
            >
              Role
            </TableCell>
            <TableCell
              align="center"
              sx={{ color: "white", fontFamily: "poppins" }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: "#2B2C40" }}>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                borderColor: "transparent",
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                align="left"
                sx={{ color: "white", fontFamily: "poppins" }}
              >
                {index + 1}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                sx={{ color: "white", fontFamily: "poppins" }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="left"
                sx={{ color: "white", fontFamily: "poppins" }}
              >
                {row.Email}
              </TableCell>
              <TableCell
                align="left"
                sx={{ color: "white", fontFamily: "poppins" }}
              >
                {row.Role}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontFamily: "poppins" }}
              >
                <DeleteIcon sx={{ cursor: "pointer" }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
