import { Typography, Container, Box, Divider, Stack } from "@mui/material";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "#222131",
        paddingTop: "20px",
        paddingBottom: "50px",
      }}
    >
      <Container
        sx={{
          maxWidth: { xs: 1100, xl: 1400 },
          color: "white",
          textDecoration: "none",
          fontFamily: "sans-serif",
        }}
      >
        <Divider
          sx={{
            marginBottom: "30px",
            backgroundColor: "white",
            width: "100%",
            marginX: "auto",
          }}
        ></Divider>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="p"
            component="p"
            sx={{
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "regular",
            }}
          >
            Copyright © 2024 | Magic Pleadings. All rights reserved.
          </Typography>

          <Stack
            direction="row"
            spacing={2}
            sx={{ width: { xs: "250px", sm: "42%", xl: "30%" } }}
          >
            <Link
              to={"policy"}
              underline="none"
              style={{ textDecoration: "none", color: "white" }}
            >
              Privacy Policy
            </Link>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: "white" }}
            />

            <Link
              to={"/terms"}
              underline="none"
              style={{ textDecoration: "none", color: "white" }}
            >
              Terms and Conditions
            </Link>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: "white" }}
            />

            <Link
              to={"/contact"}
              underline="none"
              style={{ textDecoration: "none", color: "white" }}
            >
              Contact Us
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
