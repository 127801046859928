import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const HomeMagicCard = ({ card }) => {
  return (
    <Card
      sx={{
        width: { xs: "100%", sm: "100%" },
        textAlign: { xs: "left" },
        height: "auto",
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
        padding: "0px",
      }}
    >
      <CardHeader
        sx={{ padding: "0px" }}
        avatar={
          <Box
            sx={{
              width: { xs: "90px", xl: "100px" },
              height: "50%",
              marginLeft: "-15px",
            }}
            component="img"
            src={`${card.src}?fit=crop&auto=format`}
          ></Box>
        }
      />

      <CardContent sx={{ marginTop: "-3%", padding: "0px" }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: "30px", sm: "23px", xl: "30px" },
            color: "white",
            fontFamily: "Recoletabold",
            color: "#686DFC",
          }}
        >
          {card.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: "15px", sm: "14px", xl: "20px" },
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 300,
            marginTop: "10px",
          }}
        >
          {card.content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HomeMagicCard;
