import { Box, Button, List, ListItem, ListItemText, Typography } from "@mui/material";
import axios from "axios";

import { useEffect, useState } from "react";
import { useAuth } from "../../../App";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import slices from "../../../store/user/userSlice";
import { toast } from "react-toastify";
import checkoutSlice from "../../../store/CheckoutData/checkoutDataSlice";

const SubscriptionCards = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [checkSession, setCheckSession] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.reducer?.user);
  const isAuthenticated = useSelector((state) => state?.reducer?.user?.userData?.token);
  const [isDisable, setIsDisable] = useState(false);
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  useEffect(() => {
    localStorage.removeItem("subscriptionCallMade");
    localStorage.removeItem("subscriptionData");
  }, []);

  const handleSubscriptionClick = async (data) => {
    if (data) {
      setIsDisable(true);
      if (data?.price == 0) {
        try {
          const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "createFreeSubscription", {
            headers: {
              Authorization: `Bearer ${user?.userData?.token}`,
            },
          });
          if (response.status == 200) {
            setIsDisable(false);
            showSuccessToastMessage("You have successfully subscribed the  Individual Attorney $0 / monthly plan.");
          }
        } catch (error) {}
      } else {
        const subscriptionData = {
          token: isAuthenticated,
          id: data?.id,
          planName: data?.title,
          price: data?.price,
          credits: data?.credits,
          subscriptionData: true,
        };

        dispatch(checkoutSlice.setCheckoutData(subscriptionData));
        // localStorage.setItem(
        //   "subscriptionData",
        //   JSON.stringify(subscriptionData)
        // );
        navigate("/checkout");
      }
    } else {
      showErrorToastMessage("!Error something went wrong, try again");
      navigate("/subscriptions");
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "3px solid #AB82F9",
          borderRadius: "5px", // Adjust the value as needed
          padding: { xs: "10px 10px", sm: "25px" },
          borderColor: data.currentPlan ? "transparent" : "",
          backgroundImage: data.currentPlan ? "linear-gradient(to right, #AB82F9, #696CFF)" : "",
          borderImageSlice: data.currentPlan ? 1 : "",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontFamily: "poppins" }}>
          {data.title}
        </Typography>

        <Typography variant="h6" sx={{ color: "white", fontFamily: "poppins" }}>
          ${`${data.price} / ${data.billed}`}
        </Typography>
        <hr />

        <List
          sx={{
            listStyleType: "disc",
            color: "white",
            paddingLeft: "15px",
            marginY: "20px",
            height: "120px",
          }}
        >
          <ListItem sx={{ display: "list-item", marginY: "-15px" }}>
            <ListItemText>{`${data?.noUsers} Users`}</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item", marginY: "-15px" }}>
            <ListItemText> {data?.cases}</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item", marginY: "-15px" }}>
            <ListItemText> {data?.storage}</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item", marginY: "-15px" }}>
            <ListItemText>{data?.currentPlan ? "Pay as you go with Reserve Credits only" : `${data?.credits + " Credits"} ${"/"} ${data?.creditsTenure}`}</ListItemText>
          </ListItem>
        </List>

        <Button
          disabled={isDisable || data?.currentPlan ? true : false}
          sx={{
            width: "100%",
            padding: { xs: "10px 25px", xl: "20px 50px" },
            color: data?.currentPlan ? "lightgray" : "white",
            mt: "15px",
            fontSize: { xs: "13px", xl: "20px" },
            boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
            "&:hover, &:focus": {
              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
              backgroundColor: "white",
              color: "#6a1b9a",
            },
            fontFamily: "poppins",
            backgroundColor: data.currentPlan ? "white" : "#686DFC",
            fontWeight: "800",
            textTransform: "uppercase",
          }}
          onClick={() => handleSubscriptionClick(data)}
        >
          {data.currentPlan ? "your current plan" : "Subscribe"}
        </Button>
      </Box>
    </>
  );
};

export default SubscriptionCards;
