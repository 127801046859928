import React, { useState, useRef, useEffect } from "react";
import { MenuButton } from "@mui/joy";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import { Link } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import SamplesData from "../../pages/Samples/SamplesData";
const SampleDropDown = () => {
  const [sampleOpen, setSampleOpen] = useState(false);
  const handleClick = () => {
    setSampleOpen(!sampleOpen);
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setSampleOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSampleClick = (event) => {
    event.stopPropagation();
    handleClick();
  };
  return (
    <Box sx={{ display: "flex", alignItems:'center' }} gap={2}>
      <Dropdown>
        <Link
          to={`/`}
          style={{
            textDecoration: "none",
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              color: "gray",
            },
          }}
        >
          <MenuButton
            sx={{
              fontWeight: 400,
              fontFamily: "poppins",
              fontSize: { xs: "15px", xl: "20px" },
              display: { xs: "none",md:'none', lg: "block" },
              border: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#686DFC", // Add your hover color here
              },
            }}
          >
            Home
          </MenuButton>
        </Link>
      </Dropdown>

      <Dropdown>
        <MenuButton
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "15px", xl: "20px" },
            border: "none",
            color: "white",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#686DFC",
              // backgroundColor: sampleOpen ? "white" : "#686DFC",
            },
            paddingRight: "7px",
            fontWeight: 400,
            display: { xs: "none",md:'none', lg: "flex" },
          }}
          ref={buttonRef}
          onClick={handleSampleClick}
          endDecorator={sampleOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          Samples
        </MenuButton>
        <Menu
          sx={{
            border: "none",
            padding: 1,

            "--ListItemDecorator-size": "20px",
            backgroundColor: "linear-gradient(to right, #686DFC, #758BFD)",
          }}
          placement="bottom-start" // Set placement to bottom-end
        >
          {SamplesData.map((doc) => {
            return (
              <Link
                key={doc.id}
                to={`/samples/${doc.id}`}
                style={{
                  textDecoration: "none",
                  color: "gray",
                  fontFamily: "poppins",
                }}
              >
                <MenuItem
                  sx={{
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover, &:focus": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      // backgroundColor: sampleOpen ? "white" : "#686DFC",
                      color: "gray",
                    },
                  }}
                >
                  {doc.title}
                </MenuItem>
              </Link>
            );
          })}
        </Menu>
      </Dropdown>

      <Dropdown>
        <Link
          to={`/login`}
          style={{
            textDecoration: "none",
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              color: "gray",
            },
          }}
        >
          <MenuButton
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "15px", xl: "20px" },
              display: { xs: "none",md:'none', lg: "block" },
              border: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#686DFC", // Add your hover color here
              },
              fontWeight: 400,
            }}
          >
            Login
          </MenuButton>
        </Link>
      </Dropdown>

      <Dropdown>
        <Link
          to={`/register`}
          style={{
            textDecoration: "none",
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              color: "gray",
            },
          }}
        >
          <MenuButton
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "15px", xl: "20px" },
              display: { xs: "none",md:'none', lg: "block" },
              border: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#686DFC", // Add your hover color here
                border: "2px solid #686DFC",
              },
              fontWeight: 400,
              border: "2px solid white",
            }}
          >
            Try the Magic
          </MenuButton>
        </Link>
      </Dropdown>
    </Box>
  );
};

export default SampleDropDown;
