import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducers.js";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["reducer", "checkoutReducer"],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer()),
});

export const persistor = persistStore(store);

export default store;
