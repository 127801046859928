import { combineReducers } from "@reduxjs/toolkit";
import reducer from "./user";
import checkoutReducer from "./CheckoutData";

const rootReducer = (asyncReducers) => (state, action) => {
  const combineReducer = combineReducers({
    reducer,
    checkoutReducer,
    ...asyncReducers,
  });
  return combineReducer(state, action);
};

export default rootReducer;
