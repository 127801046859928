import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const checkoutDataSlice = createSlice({
  name: "checkoutData",
  initialState,
  reducers: {
    setCheckoutData: (_, action) => {
      return action.payload;
    },
    clearCheckoutData: () => initialState,
  },
});

const { setCheckoutData, clearCheckoutData } = checkoutDataSlice.actions;
const reducer = checkoutDataSlice.reducer;

const checkoutSlice = {
  setCheckoutData: setCheckoutData,
  clearCheckoutData: clearCheckoutData,
  reducer: reducer,
};

export default checkoutSlice;
