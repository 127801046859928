import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const HomeAiCard = ({ card }) => {
  return (
    <Card
      sx={{
        padding: "10px",
        width: { xs: "85%", sm: "96%", xl: "95%" },
        textAlign: { xs: "left" },
        marginX: "auto",
        height: { xs: "auto", sm: "200px", xl: "250px" },
        borderRadius: "10px",
        boxShadow: "0.5px 0.5px 1px 1px rgba(0,0,0,0.2)",
        backgroundColor: "#222131",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: "#2B2C40",
          color: "#6a1b9a",
        },
      }}
    >
      <CardHeader
        avatar={
          <Box
            sx={{ width: { xs: "45px", xl: "60px" }, height: "45px" }}
            component="img"
            src={`${card.src}?fit=crop&auto=format`}
          ></Box>
        }
      />

      <CardContent sx={{ marginTop: "-3%" }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: "30px", xl: "30px" },
            color: "white",
            fontFamily: "Recoletabold",
          }}
        >
          {card.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: "15px", sm: "14px", xl: "20px" },
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 300,
            marginTop: "10px",
          }}
        >
          {card.content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HomeAiCard;
