import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const defaultTheme = createTheme();

const ForgetPassword = () => {
  const navigate = useNavigate();

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };

  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),

    onSubmit: async (values) => {
      try {
        const formData = new FormData();

        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "forgetpassword",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response?.data) {
          const { token } = response?.data;
          localStorage.setItem("forget_token", token);
          navigate("/login");
          showSuccessToastMessage(
            "Kindly check your email for the verification link."
          );
        }
        // Handle successful response
      } catch (error) {
        showErrorToastMessage("Please use correct email and try again");
      }
    },
  });

  React.useEffect(() => {
    if (localStorage.getItem("tokenExpire")) {
      showErrorToastMessage("Sorry! token Expires please try again");
      localStorage.removeItem("tokenExpire");
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          minHeight: "100vh",
          maxHeight: { xs: 1000, sm: 1150 },
          backgroundColor: "#222131",
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ paddingY: 4 }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
              border: "1px solid #686DFC",
              padding: "30px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#222131",
              color: "white",
            }}
          >
            <Link to={"/"}>
              <Avatar
                alt="Logo"
                sx={{ m: 2, width: 65, height: 65, cursor: "pointer" }}
                src="Magic Pleadings.png"
              />
            </Link>
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontFamily: "Recoletabold" }}
            >
              Forget Password
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={formik.touched.email && formik.errors?.email}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    variant="filled"
                    autoComplete="email"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    helperText={formik.touched?.email && formik.errors?.email}
                    sx={{ backgroundColor: "white", borderRadius: "5px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 1,
                      mb: 2,
                      px: 5,
                      boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                      "&:hover, &:focus": {
                        boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                        backgroundColor: "white",
                        color: "#6a1b9a",
                      },
                      backgroundColor: "#686DFC",
                      fontWeight: "800",
                      fontFamily: "poppins",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  sx={{
                    fontSize: { xs: "16px", sm: "14px" },
                    fontFamily: "poppins",
                  }}
                >
                  Remember Password?
                  <Link
                    to={"/login"}
                    style={{ color: "white" }}
                    variant="body2"
                  >
                    {" Login"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ForgetPassword;
