import React, { useState, useEffect, useRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { Box, Container } from "@mui/system";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import checkoutSlice from "../../store/CheckoutData/checkoutDataSlice";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = (props) => {
  const user = useSelector((state) => state?.reducer?.user);

  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const location = useLocation();
  const navigate = useNavigate();
  const getClientSecret = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "setupIntent",

        {
          headers: {
            Authorization: `Bearer ${user?.userData?.token}`,
          },
        }
      );
      setClientSecret(response?.data?.clientSecret);
    } catch (error) {
      navigate("/checkout");
    } finally {
      setLoading(false); // Update loading state regardless of success or failure
    }
  };
  useEffect(() => {
    if (user?.userData?.checkoutData && Object.keys(user?.userData?.checkoutData)?.length <= 0) {
      navigate("/");
    }

    getClientSecret();
  }, []);

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
    }
  }, [loading]);

  if (loading) {
    return (
      <Box sx={{ backgroundColor: "#222131", height: "100vh" }}>
        <Stack sx={{ width: "100%", color: "#686DFC" }} spacing={2}>
          <LinearProgress color="inherit" />
        </Stack>
      </Box>
    ); // Render loading indicator while fetching client secret
  }
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: "night",
      variables: {
        fontFamily: "poppins, sans-serif",
        fontWeightNormal: "500",
        borderRadius: "8px",
        colorBackground: "#0A2540",
        colorPrimary: "#EFC078",
        accessibleColorOnColorPrimary: "#1A1B25",
        colorText: "white",
        colorTextSecondary: "white",
        colorTextPlaceholder: "#ABB2BF",
        tabIconColor: "white",
        logoColor: "dark",
      },
      rules: {
        ".Input": {
          backgroundColor: "#212D63",
          border: "1px solid var(--colorPrimary)",
        },
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Wrapper {...props} clientSecret={clientSecret} />
    </Elements>
  );
};

const Wrapper = (props) => {
  const userState = useSelector((state) => state?.reducer?.user?.userData);
  const checkoutData = useSelector((state) => state?.checkoutReducer?.checkoutData);
  console.log("checkoutData", checkoutData);
  const dispatch = useDispatch();
  const { login } = useAuth();
  const elements = useElements();
  const stripe = useStripe();
  const location = useLocation();
  const navigate = useNavigate();

  let [isDisable, setIsDisable] = useState(false);
  const [subscriptionState, setSubscriptionState] = useState(checkoutData?.subscriptionData ? checkoutData : null);

  const [creditsState, setCreditsState] = useState(checkoutData?.creditsData ? checkoutData : null);

  const [searchParams] = useSearchParams();
  const [stopEffect, setStopEffect] = useState(false);
  const ranEffect = useRef(false);

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const createSubscription = async (url, requestData) => {
    try {
      const response = await axios.post(url, requestData, {
        headers: {
          Authorization: `Bearer ${userState?.token}`,
        },
      });

      if (response?.status === 200) {
        showSuccessToastMessage("You have successfully subscribed the Enterprise $999 / monthly plan.");
        setIsDisable(false);
        dispatch(checkoutSlice.clearCheckoutData());
        navigate("/subscriptions");
      } else {
        showErrorToastMessage("Payment failed! Please try again");
      }
    } catch (error) {
      setIsDisable(false);
      showErrorToastMessage("Payment error! Please try again");
      navigate("/subscriptions");
    }
  };
  useEffect(() => {
    if (!ranEffect.current && !stopEffect) {
      if (userState?.checkoutData && Object.keys(userState?.checkoutData).length <= 0) {
        navigate("/subscriptions");
      }

      const setupIntent = searchParams.get("setup_intent");
      const redirectStatus = searchParams.get("redirect_status");
      if (setupIntent && redirectStatus == "succeeded" && !stopEffect) {
        const requestData = {
          setupIntentId: setupIntent,
          ...(subscriptionState && {
            subscriptionPlanId: subscriptionState.id,
            planName: subscriptionState.planName,
            credits: subscriptionState.credits,
          }),
          ...(creditsState && {
            price: creditsState.price,
            credits: creditsState.credits,
          }),
        };

        if (subscriptionState) {
          createSubscription(process.env.REACT_APP_BACKEND_URL + "checkout", requestData);
        } else if (creditsState) {
          createSubscription(process.env.REACT_APP_BACKEND_URL + "createcredits", requestData);
        }
      }

      // if (subscriptionState != null) {
      //   console.log("subscriptionState", subscriptionState);
      //   const requestData = {
      //     setupIntentId: setupIntent,
      //     subscriptionPlanId: subscriptionState?.id,
      //     planName: subscriptionState?.planName,
      //     credits: subscriptionState?.credits,
      //   };

      //   const createSubscription = async () => {
      //     // Make a POST request with Axios
      //     try {
      //       const response = await axios.post(
      //         process.env.REACT_APP_BACKEND_URL + "checkout",
      //         requestData,
      //         {
      //           headers: {
      //             Authorization: `Bearer ${userData?.userData?.token}`,
      //           },
      //         }
      //       );

      //       if (response?.status == 200) {
      //         showSuccessToastMessage("Your payment was successful!");
      //         localStorage.removeItem("checkoutData");
      //         navigate("/");
      //         setIsDisable(false);
      //       } else {
      //       }
      //     } catch (error) {
      //       setIsDisable(false);
      //       showErrorToastMessage("Payment error! Please try again");
      //       localStorage.removeItem("checkoutData");
      //       navigate("/");
      //     }
      //   };

      //   if (isDisable) {
      //     createSubscription();
      //   }
      // }

      // if (creditsState != null) {
      //   console.log("creditsState", creditsState);
      //   const requestData = {
      //     setupIntentId: setupIntent,
      //     price: creditsState?.price,
      //     credits: creditsState?.credits,
      //   };

      //   const createSubscription = async () => {
      //     // Make a POST request with Axios
      //     try {
      //       const response = await axios.post(
      //         process.env.REACT_APP_BACKEND_URL + "createcredits",
      //         requestData
      //       );

      //       if (response?.status == 200) {
      //         showSuccessToastMessage("Your payment was successful!");
      //         setIsDisable(false);
      //         navigate("/");
      //       } else {
      //       }
      //     } catch (error) {
      //       setIsDisable(false);
      //       showErrorToastMessage("Payment error! Please try again");
      //       navigate("/");
      //     }
      //   };

      //   if (isDisable) {
      //     createSubscription();
      //     localStorage.setItem("subscriptionCallMade", "true");
      //   }
      // }
      ranEffect.current = true;
    }
  }, [stopEffect]);

  const handleSubmit = async (event) => {
    setIsDisable(true);
    event.preventDefault();
    if (elements && props.clientSecret) {
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
      if (result.error) {
        setIsDisable(false);
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
        backgroundColor: "#222131",
      }}
    >
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          paddingY: { xs: 8, sm: 4 },
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 0, sm: 8 },
            boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
            border: "1px solid #686DFC",
            padding: "30px",
            borderRadius: "5px",
            backgroundColor: "#222131",
            color: "white",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Typography
              component="h1"
              variant="h4"
              sx={{
                fontFamily: "Recoletabold",
                textAlign: "center",
                fontSize: { xs: "25px", sm: "35px" },
              }}
            >
              {creditsState?.credits ? "Credits Payment" : "Subscription\u00A0\u00A0Payment"}
            </Typography>
            <Grid
              container
              sx={{
                paddingY: "10px",
                fontFamily: "poppins",
                textAlign: "center",
                width: "100%",
                backgroundColor: "#212D63",
                border: "1px solid #EFC078",
                borderRadius: "8px",
              }}
            >
              {creditsState && (
                <Grid item xs={12} sm={12} sx={{ width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography component="h1" variant="h6">
                      Credits
                    </Typography>

                    <Typography component="h1" variant="h6">
                      price
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography component="h1" variant="h6">
                        {creditsState?.credits}
                      </Typography>

                      <Typography component="h1" variant="h6">
                        {creditsState?.price}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {subscriptionState && (
                <Grid item xs={12} sm={12} sx={{ width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography>
                      <Typography component="h1" variant="h6">
                        Plan Name
                      </Typography>

                      <Typography component="h1" variant="h6" sx={{ textAlign: "left" }}>
                        {subscriptionState?.planName}
                      </Typography>
                    </Typography>

                    <Typography>
                      <Typography component="h1" variant="h6">
                        Price
                      </Typography>

                      <Typography component="h1" variant="h6" sx={{ textAlign: "left" }}>
                        ${subscriptionState?.price}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <TextField
              hiddenLabel
              disabled
              id="filled-disabled"
              defaultValue={userState?.email}
              variant="filled"
              InputProps={{
                style: { color: "lightgray" }, // Set text color to white
              }}
              margin="normal"
              sx={{
                width: "100%",
                backgroundColor: "#212D63",
                border: "1px solid #EFC078",
                borderRadius: "8px",

                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "lightgray",
                },
              }}
            />

            <PaymentElement />
            {isDisable ? (
              <Box
                sx={{
                  marginY: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                disabled={isDisable}
                type="submit"
                variant="contained"
                sx={{
                  width: "100%",
                  mt: 1.5,
                  mb: 2,
                  boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                  "&:hover, &:focus": {
                    boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },

                  backgroundColor: "#686DFC",
                  fontWeight: "800",
                  fontFamily: "poppins",
                }}
              >
                {creditsState?.credits ? "Buy credits" : "Subscribe"}
              </Button>
            )}
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default CheckoutForm;
