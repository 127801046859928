import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../components/NavBar";
import ContactUsForm from "../../components/Form/ContactUsForm";

const ContactUs = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{  minHeight: '100vh', bgcolor: "#222131" }}>
      <Navbar />

      <Container
        sx={{
          maxWidth: { xs: "md", xl: 1100 },
          marginTop: { sm: "-40px", lg: "-60px" },
          paddingBottom: "40px",
        }}
      >
        <Grid
          container
          sx={{
            paddingY: { xl: "5%" },
            textAlign: { xs: "center", sm: "left" },
            backgroundColor: "#222131",
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            <Typography
              color="white"
              fontWeight="bold"
              mt={"120px"}
              // width={{ xs: "100%", md: "95%", lg: "80%" }}

              fontSize={{ xs: "50px", sm: "55px", xl: "80px" }}
              fontFamily="Recoletabold"
              lineHeight="1"
            >
              <span style={{ color: "#686DFC" }}>Ready</span> to streamline
              document Generation With{" "}
              <span style={{ color: "#686DFC" }}>AI</span>
            </Typography>
            <Typography
              // width={{ xs: "100%", sm: "80%", xl: "75%" }}
              sx={{
                fontSize: { xs: "20px", xl: "25px" },
                fontFamily: "Recoleta regular",
                color: "#FFFFFF",
                marginTop: "20px",
              }}
            >
              For all your questions about our AI-powered legal solutions, feel
              free to email us using the form below. Our dedicated team is here
              to assist you and ensure you find the right solution for your
              needs.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            textAlign: { xs: "center" },
            backgroundColor: "#222131",
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              marginTop: 4,
              boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
              border: "1px solid #686DFC",
              padding: { sm: "30px", xs: "8px" },
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#222131",
              color: "white",
            }}
          >
            <ContactUsForm />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUs;
