import "./App.css";
import HomePage from "./pages/Home.js";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer/index.js";
import SignUp from "./pages/AUTH/SignUp.js";
import SignIn from "./pages/AUTH/SignIn.js";
import ForgetPassword from "./pages/AUTH/ForgetPassword.js";
import ResetPassword from "./pages/AUTH/ResetPassword.js";
import Terms from "./pages/Terms.js";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy.js";
import { ToastContainer } from "react-toastify";
import { createContext, useContext, useState } from "react";
import SamplesData from "./pages/Samples/SamplesData.js";
import SamplesDoc from "./pages/Samples/SamplesDoc.js";
import IframeData from "./pages/Iframes/IframeData.js";
import Iframe from "./pages/Iframes/Iframe.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";
import Subscriptions from "./pages/Subscriptions/index.js";
import CheckoutForm from "./pages/Checkout/index.js";
import { useDispatch, useSelector } from "react-redux";
import slices from "./store/user/userSlice.js";
 import ProtectedRoute from "./pages/ProtectedRoute/index.js";
import NotFound from "./pages/NotFound/index.js";
 
const AuthContext = createContext();

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  console.log('pathname:' , pathName);
  const dispatch = useDispatch();
  const authenticatedUserData = useSelector((state) => state?.reducer?.user);

  const isAuthenticated = authenticatedUserData !== null && typeof authenticatedUserData === "object" && Object.keys(authenticatedUserData).length > 0;

  const login = (userData) => {
    // authenticatedUserData;
    console.log("app component login____________");
    if (userData) {
      dispatch(slices.setUser(userData));
    }
  };

  const logout = () => {
    dispatch(slices.clearUser());
    // Perform logout logic here, e.g., clear tokens

    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      <ToastContainer autoClose={6000} />
      <Routes>
        <Route index element={<HomePage />} />

        <>
          {/* student Iframes */}
          {IframeData.IframeStudentData.map((doc) => (
            <Route
              key={doc.id}
              path={`/students/${doc.id}`}
              element={
                <ProtectedRoute>
                  <Iframe title={doc.title} iframeSrc={doc.iframeSrc} />
                </ProtectedRoute>
              }
            />
          ))}
          {/* pleadings Iframes */}
          {IframeData.IframePleadingsData.map((doc) => (
            <Route
              key={doc.id}
              path={`/pleadings/${doc.id}`}
              element={
                <ProtectedRoute>
                  <Iframe title={doc.title} iframeSrc={doc.iframeSrc} />
                </ProtectedRoute>
              }
            />
          ))}
          {/* medical Iframes */}
          {IframeData.IframeMedicalData.map((doc) => (
            <Route
              key={doc.id}
              path={`/medical/${doc.id}`}
              element={
                <ProtectedRoute>
                  <Iframe title={doc.title} iframeSrc={doc.iframeSrc} />
                </ProtectedRoute>
              }
            />
          ))}
          <Route
            path="/subscriptions"
            element={
              <ProtectedRoute>
                <Subscriptions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <CheckoutForm />
              </ProtectedRoute>
            }
          />
        </>

        {/* {!isAuthenticated && } */}

        {/* Samples Pleadings components */}
        {SamplesData.map((doc) => (
          <Route key={doc.id} path={`/samples/${doc.id}`} element={<SamplesDoc title={doc.title} imageSrc={doc.imageSrc} imageSrcCSV={doc?.imageSrcCSV} />} />
        ))}
         {/* Auth components */}
        <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<PrivacyAndPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {pathName !== "/login" && pathName !== "/register" && pathName !== "/forget-password" && pathName !== "/reset-password" && <Footer />}
    </AuthContext.Provider>
  );
}

export default App;
export const useAuth = () => useContext(AuthContext);
