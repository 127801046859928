const SamplesData = [
  {
    id: 1,
    title: "Single Assignment of Error",
    imageSrc: "../../../Assignment-of-error-analysis.png",
  },
  {
    id: 2,
    title: "Case Law IRAC Analysis",
    imageSrc: "../../../IRAC.png",
  },
  {
    id: 3,
    title: "Single Provider Medical Visit Report - Manual Input",
    imageSrc:
      "../../../Single-Provider-Medical-Record-Report-Manual-Input-word.png",
    imageSrcCSV:
      "../../../Single-Provider-Medical-Record-Report-Manual-Input-csv.png",
  },
  {
    id: 4,
    title: "Single Provider Medical Visit Report - Upload Records",
    imageSrc:
      "../../../Single-Provider-Medical-Visit-Report-Upload-Records-word.png",
    imageSrcCSV:
      "../../../Single-Provider-Medical-Visit-Report-Upload-Records-csv.png",
  },

  {
    id: 5,
    title: "Multi-Provider Medical Visit Report - Manual Input",
    imageSrc:
      "../../../Multi-Provider-Medical-Visit-Report-Manual-Input-word.png",
    imageSrcCSV:
      "../../../Multi-Provider-Medical-Visit-Report-Manual-Input-csv.png",
  },

  {
    id: 6,
    title: "Memorandum in Support of Motion for Reconsideration",
    imageSrc:
      "../../../Memorandum-in support-of-Motion-for-Reconsideration.png",
  },

  {
    id: 7,
    title: "Statement of Material Facts",
    imageSrc: "../../../Statement-of-material.png",
  },
  {
    id: 8,
    title: "Single Issue Supporting Memorandum",
    imageSrc: "../../../content-Two.png",
  },
  {
    id: 9,
    title: "Written Reasons For Judgment",
    imageSrc: "../../../Reason-for-judgment.png",
  },
];

export default SamplesData;
