import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import slices from "../../../store/user/userSlice";
import checkoutSlice from "../../../store/CheckoutData/checkoutDataSlice";

const Credits = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state?.reducer?.user?.userData?.token
  );

  useEffect(() => {
    localStorage.removeItem("subscriptionCallMade");
    localStorage.removeItem("creditsData");
  }, []);
  const handleClick = (data) => {
    const subscriptionData = {
      token: isAuthenticated,
      price: data.price,
      credits: data.credits,
      creditsData: true,
    };
    dispatch(checkoutSlice.setCheckoutData(subscriptionData));

    // localStorage.setItem("creditsData", JSON.stringify(subscriptionData));

    navigate("/checkout");
  };

  return (
    <Card
      sx={{
        backgroundColor: "#2B2C40",
        width: { xs: "100%", sm: "230px", md: "100%" },
        height: "auto",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: "60px",
              height: "auto",
              borderRadius: "20px",
              marginRight: "20px",
            }}
            component="img"
            src={`credits.png?fit=crop&auto=format`}
          ></Box>
          <Typography
            variant="h6"
            sx={{ color: "white", fontFamily: "poppins", fontSize: "17px" }}
          >
            {data?.credits} Credits
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontFamily: "poppins",
                color: "#686DFC",
                fontSize: "16px",
              }}
            >
              ${data?.price}
            </Typography>
          </Typography>
        </Box>
        <Button
          sx={{
            width: "100%",
            padding: { xs: "7px 25px", xl: "20px 50px" },
            color: "white",
            mt: "15px",
            fontSize: { xs: "13px", xl: "20px" },
            boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
            "&:hover, &:focus": {
              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
              backgroundColor: "white",
              color: "#6a1b9a",
            },
            fontFamily: "poppins",
            backgroundColor: "#686DFC",
            fontWeight: "800",
            textTransform: "uppercase",
          }}
          onClick={() => {
            handleClick(data);
          }}
        >
          Buy
        </Button>
      </CardContent>
    </Card>
  );
};

export default Credits;
