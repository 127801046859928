const ListPleadingsDocData = [
  {
    id: 1,
    heading: "Pleadings",
    title: "Memorandum in Support of Motion for Reconsideration",
    linkSrc: "/samples/6",
    content:
      "The user upload the court's reasons for judgment. The user will also copy and paste the facts, statements of law, and rulings from three judicial decisions and explain why the court erred in its analysis. An AI attorney analyzes the above information and writes a memorandum in support of a motion for reconsideration.",
  },
  {
    id: 2,
    heading: "Pleadings",
    title: "Single Assignment of Error",
    linkSrc: `/samples/1`,
    content:
      "The user copies and pastes the facts, statements of law, and rulings from three judicial decisions. The user also copies and pastes the court's ruling and explains the basis for the assignment of error. An AI attorney analyzes the above information and writes a section of an appellate brief explaining the lower court's alleged error.",
  },
  {
    id: 3,
    heading: "Pleadings",
    title: "Single Issue Legal Memorandums",
    linkSrc: `/samples/8`,
    content:
      "The user copies and pastes the facts, statements of law, and rulings from three judicial decisions. The user also copies and pastes the facts of the case sub judice. An AI attorney analyzes the above information and writes a complete supporting memorandum, includes  introduction, statement of facts, legal analysis, & conclusion.",
  },
  {
    id: 4,
    heading: "Pleadings",
    title: "Statement of Material Facts",
    linkSrc: `/samples/7`,
    content:
      "The user uploads a full-text version of the deposition. An AI attorney will review the deposition page by page and write a series of factual statements discussed on each page.",
  },
  {
    id: 5,
    heading: "Pleadings",
    title: "Written Reasons for Judgment",
    linkSrc: `/samples/9`,
    content:
      "The user uploads the moving party's motion and the non-moving party's opposition memorandum. The user also identifies the legal issue, the relief granted, and the court's reasons for granting said relief. An AI attorney analyzes the above information and writes a written reasons for judgment in conformity with the user's directives.",
  },
];

const ListMedicalDocData = [
  {
    id: 1,
    heading: "Medicals",
    title: "Single Provider Medical Visit Report - Manual Input",
    linkSrc: `/samples/3`,
    content:
      "[MOST ACCURATE] The user manually copies and pastes the patient's medical record information directly from the medical record into a form. The medical record information should relate to a single healthcare provider, clinic, or hospital. An AI medical doctor will review the medical records and create a spreadsheet containing the date of medical visit, complaints, diagnoses, and medical treatments, if applicable. The user will also receive a Word file containing a summary of the medical treatments in narrative form for use in settlement demands and correspondence.",
  },
  {
    id: 2,
    title: "Single Provider Medical Visit Report - Upload Records",
    linkSrc: `/samples/4`,
    content:
      "[MOST EFFICIENT] The user uploads the patient's medical records into the system. The user must provide the specific page indices (ranges) for each encounter date. The medical records should relate to a single healthcare provider, clinic, or hospital. An AI medical doctor will review the medical records and create a spreadsheet containing the date of the medical visit, complaints, diagnoses, and medical treatments, if applicable. The user will also receive a Word file containing a summary of the medical treatments in narrative form for use in settlement demands and correspondence.",
  },

  {
    id: 2,
    title: "Multi-Provider Medical Visit Report - Manual Input",
    linkSrc: `/samples/5`,
    content:
      "[MOST ACCURATE] The user manually copies and pastes the patient's medical record information directly from the medical records into a form. The medical record information can relate to one or more healthcare providers, clinics, or hospitals. An AI medical doctor will review the medical records and create a spreadsheet containing the date of medical visit, healthcare provider, complaints, diagnoses, and medical treatments, if applicable. The user will also receive a Word file containing a summary of the medical treatments in narrative form for use in settlement demands and correspondence.",
  },
];

const ListStudentsDocData = [
  {
    id: 1,
    heading: "Students",
    title: "IRAC Analysis",
    linkSrc: `/samples/2`,
    content:
      "The user uploads the case under study and identifies the relevant legal issue. An AI attorney will perform an IRAC analysis, identifying the required legal elements and performing an analysis of the legal elements in the uploaded case. ",
  },
];

export default {
  ListPleadingsDocData,
  ListStudentsDocData,
  ListMedicalDocData,
};
